/* 北京赛车 */
export default {
  plays: {
    0: {
      rule_id: 64000,
      area_id: 64000,
      play_name: 'DWD',
      play_cname: '定位胆',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    100: {
      rule_id: 64100,
      area_id: 64100,
      play_name: 'DXDS',
      play_cname: '大小单双',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    200: {
      rule_id: 64200,
      area_id: 64200,
      play_name: 'CGJ',
      play_cname: '猜冠军',
      special: false,
      unit_price: 1,
      note_tag: 'count'
    },
    300: {
      rule_id: 64300,
      area_id: 64300,
      play_name: 'CGYJ',
      play_cname: '猜冠亚军',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    400: {
      rule_id: 64400,
      area_id: 64400,
      play_name: 'CQ3M',
      play_cname: '猜前三名',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    500: {
      rule_id: 64500,
      area_id: 64500,
      play_name: 'CQ4M',
      play_cname: '猜前四名',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
    600: {
      rule_id: 64600,
      area_id: 64600,
      play_name: 'CQ5M',
      play_cname: '猜前五名',
      special: false,
      unit_price: 1,
      note_tag: 'qianN'
    },
  }
}
