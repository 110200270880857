// 号码球方案
export const BallPan = {
  // PK10
  101: {
    balls: [
      { id: 1, name: "01", cmd: "01" },
      { id: 2, name: "02", cmd: "02" },
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
      { id: 7, name: "07", cmd: "07" },
      { id: 8, name: "08", cmd: "08" },
      { id: 9, name: "09", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
    ],
  },
  102: {
    balls: [
      { id: 1, name: "大", cmd: "00" },
      { id: 2, name: "小", cmd: "01" },
      { id: 3, name: "单", cmd: "02" },
      { id: 4, name: "双", cmd: "03" },
    ],
  },
  103: {
    balls: [
      { id: 1, name: "龙", cmd: "0" },
      { id: 2, name: "虎", cmd: "1" },
    ],
  },
  104: {
    balls: [
      { id: 1, name: "单", cmd: "00" },
      { id: 2, name: "双", cmd: "01" },
    ],
  },
  105: {
    balls: [
      { id: 1, name: "大", cmd: "00" },
      { id: 2, name: "小", cmd: "01" },
    ],
  },
  106: {
    balls: [
      { id: 1, name: "03", cmd: "03" },
      { id: 2, name: "04", cmd: "04" },
      { id: 3, name: "05", cmd: "05" },
      { id: 4, name: "06", cmd: "06" },
      { id: 5, name: "07", cmd: "07" },
      { id: 6, name: "08", cmd: "08" },
      { id: 7, name: "09", cmd: "09" },
      { id: 8, name: "10", cmd: "10" },
      { id: 9, name: "11", cmd: "11" },
      { id: 10, name: "12", cmd: "12" },
      { id: 11, name: "13", cmd: "13" },
      { id: 12, name: "14", cmd: "14" },
      { id: 13, name: "15", cmd: "15" },
      { id: 14, name: "16", cmd: "16" },
      { id: 15, name: "17", cmd: "17" },
      { id: 16, name: "18", cmd: "18" },
      { id: 17, name: "19", cmd: "19" },
    ],
  },
  // PC00/加拿大28
  201: {
    balls: [
      { id: 0, name: "0", cmd: "00" },
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
      { id: 19, name: "19", cmd: "19" },
      { id: 20, name: "20", cmd: "20" },
      { id: 21, name: "21", cmd: "21" },
      { id: 22, name: "22", cmd: "22" },
      { id: 23, name: "23", cmd: "23" },
      { id: 24, name: "24", cmd: "24" },
      { id: 25, name: "25", cmd: "25" },
      { id: 26, name: "26", cmd: "26" },
      { id: 27, name: "27", cmd: "27" },
    ],
  },
  202: {
    balls: [
      { id: 0, name: "大", cmd: "00" },
      { id: 2, name: "单", cmd: "02" },
      { id: 5, name: "大单", cmd: "05", is_com_amount: true },
      { id: 7, name: "小单", cmd: "07", is_com_amount: true },
      { id: 8, name: "极大", cmd: "08" },
      { id: 1, name: "小", cmd: "01" },
      { id: 3, name: "双", cmd: "03" },
      { id: 4, name: "大双", cmd: "04", is_com_amount: true },
      { id: 6, name: "小双", cmd: "06", is_com_amount: true },
      { id: 9, name: "极小", cmd: "09" },
    ],
  },
  203: {
    balls: [
      { id: 0, name: "红波", cmd: "0" },
      { id: 1, name: "绿波", cmd: "1" },
      { id: 2, name: "蓝波", cmd: "2" },
    ],
  },
  204: {
    balls: [{ id: 0, name: "豹子", cmd: "0" }],
  },
  205: {
    balls: [
      { id: 0, name: "特别码", cmd: "0" }],
  },
  "206": {
    balls: [
      {
        id: 10,
        name: "大",
        cmd: "10",
      },
      {
        id: 11,
        name: "小",
        cmd: "11",
      },
      {
        id: 12,
        name: "单",
        cmd: "12",
      },
      {
        id: 13,
        name: "双",
        cmd: "13",
      },
      {
        id: 14,
        name: "龙",
        cmd: "14",
      },
      {
        id: 0,
        name: "0",
        cmd: "00",
      },
      {
        id: 1,
        name: "1",
        cmd: "01",
      },
      {
        id: 2,
        name: "2",
        cmd: "02",
      },
      {
        id: 3,
        name: "3",
        cmd: "03",
      },
      {
        id: 4,
        name: "4",
        cmd: "04",
      },
      {
        id: 5,
        name: "5",
        cmd: "05",
      },
      {
        id: 6,
        name: "6",
        cmd: "06",
      },
      {
        id: 7,
        name: "7",
        cmd: "07",
      },
      {
        id: 8,
        name: "8",
        cmd: "08",
      },
      {
        id: 9,
        name: "9",
        cmd: "09",
      },
    ],
  },
  "207": {
    balls: [
      {
        id: 10,
        name: "大",
        cmd: "10",
      },
      {
        id: 11,
        name: "小",
        cmd: "11",
      },
      {
        id: 12,
        name: "单",
        cmd: "12",
      },
      {
        id: 13,
        name: "双",
        cmd: "13",
      },
      {
        id: 15,
        name: "和",
        cmd: "15",
      },
      {
        id: 0,
        name: "0",
        cmd: "00",
      },
      {
        id: 1,
        name: "1",
        cmd: "01",
      },
      {
        id: 2,
        name: "2",
        cmd: "02",
      },
      {
        id: 3,
        name: "3",
        cmd: "03",
      },
      {
        id: 4,
        name: "4",
        cmd: "04",
      },
      {
        id: 5,
        name: "5",
        cmd: "05",
      },
      {
        id: 6,
        name: "6",
        cmd: "06",
      },
      {
        id: 7,
        name: "7",
        cmd: "07",
      },
      {
        id: 8,
        name: "8",
        cmd: "08",
      },
      {
        id: 9,
        name: "9",
        cmd: "09",
      },
    ],
  },
  "208": {
    balls: [
      {
        id: 10,
        name: "大",
        cmd: "10",
      },
      {
        id: 11,
        name: "小",
        cmd: "11",
      },
      {
        id: 12,
        name: "单",
        cmd: "12",
      },
      {
        id: 13,
        name: "双",
        cmd: "13",
      },
      {
        id: 16,
        name: "虎",
        cmd: "16",
      },
      {
        id: 0,
        name: "0",
        cmd: "00",
      },
      {
        id: 1,
        name: "1",
        cmd: "01",
      },
      {
        id: 2,
        name: "2",
        cmd: "02",
      },
      {
        id: 3,
        name: "3",
        cmd: "03",
      },
      {
        id: 4,
        name: "4",
        cmd: "04",
      },
      {
        id: 5,
        name: "5",
        cmd: "05",
      },
      {
        id: 6,
        name: "6",
        cmd: "06",
      },
      {
        id: 7,
        name: "7",
        cmd: "07",
      },
      {
        id: 8,
        name: "8",
        cmd: "08",
      },
      {
        id: 9,
        name: "9",
        cmd: "09",
      },

    ],
  },
  209: {
    balls: [{ id: 0, name: "顺子", cmd: "0" }],
  },
  210: {
    balls: [{ id: 0, name: "对子", cmd: "0" }],
  },
  // 时时彩
  301: {
    balls: [
      { id: 0, name: "0", cmd: "0" },
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
      { id: 7, name: "7", cmd: "7" },
      { id: 8, name: "8", cmd: "8" },
      { id: 9, name: "9", cmd: "9" },
    ],
  },
  // 和值
  302: {
    balls: [
      { id: 0, name: "0", cmd: "00" },
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
    ],
  },
  303: {
    balls: [
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
    ],
  },
  // 前三/中三/后三直选和值
  304: {
    balls: [
      { id: 0, name: "0", cmd: "00" },
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
      { id: 19, name: "19", cmd: "19" },
      { id: 20, name: "20", cmd: "20" },
      { id: 21, name: "21", cmd: "21" },
      { id: 22, name: "22", cmd: "22" },
      { id: 23, name: "23", cmd: "23" },
      { id: 24, name: "24", cmd: "24" },
      { id: 25, name: "25", cmd: "25" },
      { id: 26, name: "26", cmd: "26" },
      { id: 27, name: "27", cmd: "27" },
    ],
  },
  // 前三/中三/后三组选和值
  305: {
    balls: [
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
      { id: 19, name: "19", cmd: "19" },
      { id: 20, name: "20", cmd: "20" },
      { id: 21, name: "21", cmd: "21" },
      { id: 22, name: "22", cmd: "22" },
      { id: 23, name: "23", cmd: "23" },
      { id: 24, name: "24", cmd: "24" },
      { id: 25, name: "25", cmd: "25" },
      { id: 26, name: "26", cmd: "26" },
    ],
  },
  // 前三特殊号
  306: {
    balls: [
      { id: 0, name: "豹子", cmd: "00" },
      { id: 1, name: "对子", cmd: "01" },
      { id: 2, name: "顺子", cmd: "02" },
    ],
  },
  // 大小单双
  307: {
    balls: [
      { id: 0, name: "大", cmd: "00" },
      { id: 1, name: "小", cmd: "01" },
      { id: 2, name: "单", cmd: "02" },
      { id: 3, name: "双", cmd: "03" },
    ],
  },
  // 任选选择框
  308: {
    balls: [
      { id: 1, name: "万位", cmd: "1" },
      { id: 2, name: "千位", cmd: "2" },
      { id: 3, name: "百位", cmd: "3" },
      { id: 4, name: "十位", cmd: "4" },
      { id: 5, name: "个位", cmd: "5" },
    ],
  },
  // 快3
  // 和值
  401: {
    balls: [
      { id: 0, name: "大", cmd: "19" },
      { id: 1, name: "小", cmd: "20" },
      { id: 2, name: "单", cmd: "21" },
      { id: 3, name: "双", cmd: "22" },
      { id: 4, name: "3", cmd: "03" },
      { id: 5, name: "4", cmd: "04" },
      { id: 6, name: "5", cmd: "05" },
      { id: 7, name: "6", cmd: "06" },
      { id: 8, name: "7", cmd: "07" },
      { id: 9, name: "8", cmd: "08" },
      { id: 10, name: "9", cmd: "09" },
      { id: 11, name: "10", cmd: "10" },
      { id: 12, name: "11", cmd: "11" },
      { id: 13, name: "12", cmd: "12" },
      { id: 14, name: "13", cmd: "13" },
      { id: 15, name: "14", cmd: "14" },
      { id: 16, name: "15", cmd: "15" },
      { id: 17, name: "16", cmd: "16" },
      { id: 18, name: "17", cmd: "17" },
      { id: 19, name: "18", cmd: "18" },
    ],
  },
  // 三同号通选
  402: {
    balls: [{ id: 0, name: "三同号通选", cmd: "0" }],
  },
  // 三同号单选
  403: {
    balls: [
      { id: 1, name: "111", cmd: "1" },
      { id: 2, name: "222", cmd: "2" },
      { id: 3, name: "333", cmd: "3" },
      { id: 4, name: "444", cmd: "4" },
      { id: 5, name: "555", cmd: "5" },
      { id: 6, name: "666", cmd: "6" },
    ],
  },
  // 三不同号
  404: {
    balls: [
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
    ],
  },
  // 三连号通选
  405: {
    balls: [{ id: 0, name: "三连号通选", cmd: "0" }],
  },
  // 二同号单选
  407: {
    balls: [
      { id: 1, name: "11", cmd: "1" },
      { id: 2, name: "22", cmd: "2" },
      { id: 3, name: "33", cmd: "3" },
      { id: 4, name: "44", cmd: "4" },
      { id: 5, name: "55", cmd: "5" },
      { id: 6, name: "66", cmd: "6" },
    ],
  },
  // 二不同号
  409: {
    balls: [
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
    ],
  },
  // 虚拟币
  // 反投
  411: {
    balls: [
      { id: "10", name: "大", cmd: "10" },
      { id: "11", name: "小", cmd: "11" },
      { id: "12", name: "单", cmd: "12" },
      { id: "13", name: "双", cmd: "13" },
      { id: "00", name: "反投 0", cmd: "00" },
      { id: "01", name: "反投 1", cmd: "01" },
      { id: "02", name: "反投 2", cmd: "02" },
      { id: "03", name: "反投 3", cmd: "03" },
      { id: "04", name: "反投 4", cmd: "04" },
      { id: "05", name: "反投 5", cmd: "05" },
      { id: "06", name: "反投 6", cmd: "06" },
      { id: "07", name: "反投 7", cmd: "07" },
      { id: "08", name: "反投 8", cmd: "08" },
      { id: "09", name: "反投 9", cmd: "09" },
    ],
  },
  // 和值
  412: {
    balls: [
      { id: "00", name: "和值 0", cmd: "00" },
      { id: "01", name: "和值 1", cmd: "01" },
      { id: "02", name: "和值 2", cmd: "02" },
      { id: "03", name: "和值 3", cmd: "03" },
      { id: "04", name: "和值 4", cmd: "04" },
      { id: "05", name: "和值 5", cmd: "05" },
      { id: "06", name: "和值 6", cmd: "06" },
      { id: "07", name: "和值 7", cmd: "07" },
      { id: "08", name: "和值 8", cmd: "08" },
      { id: "09", name: "和值 9", cmd: "09" },
      { id: "10", name: "和值 10", cmd: "10" },
      { id: "11", name: "和值 11", cmd: "11" },
      { id: "12", name: "和值 12", cmd: "12" },
      { id: "13", name: "和值 13", cmd: "13" },
      { id: "14", name: "和值 14", cmd: "14" },
      { id: "15", name: "和值 15", cmd: "15" },
      { id: "16", name: "和值 16", cmd: "16" },
      { id: "17", name: "和值 17", cmd: "17" },
      { id: "18", name: "和值 18", cmd: "18" },
      { id: "19", name: "和值 19", cmd: "19" },
      { id: "20", name: "和值 20", cmd: "20" },
      { id: "21", name: "和值 21", cmd: "21" },
      { id: "22", name: "和值 22", cmd: "22" },
      { id: "23", name: "和值 23", cmd: "23" },
      { id: "24", name: "和值 24", cmd: "24" },
      { id: "25", name: "和值 25", cmd: "25" },
      { id: "26", name: "和值 26", cmd: "26" },
      { id: "27", name: "和值 27", cmd: "27" },
      { id: "28", name: "大", cmd: "28" },
      { id: "29", name: "小", cmd: "29" },
      { id: "30", name: "单", cmd: "30" },
      { id: "31", name: "双", cmd: "31" },
    ],
  },
  // 正投
  413: {
    balls: [
      { id: "10", name: "大", cmd: "10" },
      { id: "11", name: "小", cmd: "11" },
      { id: "12", name: "单", cmd: "12" },
      { id: "13", name: "双", cmd: "13" },
      { id: "00", name: "正投 0", cmd: "00" },
      { id: "01", name: "正投 1", cmd: "01" },
      { id: "02", name: "正投 2", cmd: "02" },
      { id: "03", name: "正投 3", cmd: "03" },
      { id: "04", name: "正投 4", cmd: "04" },
      { id: "05", name: "正投 5", cmd: "05" },
      { id: "06", name: "正投 6", cmd: "06" },
      { id: "07", name: "正投 7", cmd: "07" },
      { id: "08", name: "正投 8", cmd: "08" },
      { id: "09", name: "正投 9", cmd: "09" },
    ],
  },
  // 和值反投
  414: {
    balls: [
      { id: "00", name: "和值反投 0", cmd: "00" },
      { id: "01", name: "和值反投 1", cmd: "01" },
      { id: "02", name: "和值反投 2", cmd: "02" },
      { id: "03", name: "和值反投 3", cmd: "03" },
      { id: "04", name: "和值反投 4", cmd: "04" },
      { id: "05", name: "和值反投 5", cmd: "05" },
      { id: "06", name: "和值反投 6", cmd: "06" },
      { id: "07", name: "和值反投 7", cmd: "07" },
      { id: "08", name: "和值反投 8", cmd: "08" },
      { id: "09", name: "和值反投 9", cmd: "09" },
      { id: "10", name: "和值反投 10", cmd: "10" },
      { id: "11", name: "和值反投 11", cmd: "11" },
      { id: "12", name: "和值反投 12", cmd: "12" },
      { id: "13", name: "和值反投 13", cmd: "13" },
      { id: "14", name: "和值反投 14", cmd: "14" },
      { id: "15", name: "和值反投 15", cmd: "15" },
      { id: "16", name: "和值反投 16", cmd: "16" },
      { id: "17", name: "和值反投 17", cmd: "17" },
      { id: "18", name: "和值反投 18", cmd: "18" },
      { id: "19", name: "和值反投 19", cmd: "19" },
      { id: "20", name: "和值反投 20", cmd: "20" },
      { id: "21", name: "和值反投 21", cmd: "21" },
      { id: "22", name: "和值反投 22", cmd: "22" },
      { id: "23", name: "和值反投 23", cmd: "23" },
      { id: "24", name: "和值反投 24", cmd: "24" },
      { id: "25", name: "和值反投 25", cmd: "25" },
      { id: "26", name: "和值反投 26", cmd: "26" },
      { id: "27", name: "和值反投 27", cmd: "27" },
      { id: "28", name: "大", cmd: "28" },
      { id: "29", name: "小", cmd: "29" },
      { id: "30", name: "单", cmd: "30" },
      { id: "31", name: "双", cmd: "31" },
    ],
  },
  // 后二和值
  415: {
    balls: [
      { id: "00", name: "后二和值 0", cmd: "00" },
      { id: "01", name: "后二和值 1", cmd: "01" },
      { id: "02", name: "后二和值 2", cmd: "02" },
      { id: "03", name: "后二和值 3", cmd: "03" },
      { id: "04", name: "后二和值 4", cmd: "04" },
      { id: "05", name: "后二和值 5", cmd: "05" },
      { id: "06", name: "后二和值 6", cmd: "06" },
      { id: "07", name: "后二和值 7", cmd: "07" },
      { id: "08", name: "后二和值 8", cmd: "08" },
      { id: "09", name: "后二和值 9", cmd: "09" },
      { id: "10", name: "后二和值 10", cmd: "10" },
      { id: "11", name: "后二和值 11", cmd: "11" },
      { id: "12", name: "后二和值 12", cmd: "12" },
      { id: "13", name: "后二和值 13", cmd: "13" },
      { id: "14", name: "后二和值 14", cmd: "14" },
      { id: "15", name: "后二和值 15", cmd: "15" },
      { id: "16", name: "后二和值 16", cmd: "16" },
      { id: "17", name: "后二和值 17", cmd: "17" },
      { id: "18", name: "后二和值 18", cmd: "18" },
    ],
  },
  // 区块
  416: {
    balls: [
      { id: "01", name: "大", cmd: "01" },
      { id: "02", name: "小", cmd: "02" },
      { id: "03", name: "单", cmd: "03" },
      { id: "04", name: "双", cmd: "04" },
      { id: "05", name: "大单", cmd: "05" },
      { id: "06", name: "大双", cmd: "06" },
      { id: "07", name: "小单", cmd: "07" },
      { id: "08", name: "小双", cmd: "08" },
    ],
  },

  /* 重庆六合彩 */
  // 特码
  501: {
    balls: [
      { id: 1, name: "01", cmd: "01", color: 0 },
      { id: 2, name: "02", cmd: "02", color: 0 },
      { id: 3, name: "03", cmd: "03", color: 2 },
      { id: 4, name: "04", cmd: "04", color: 2 },
      { id: 5, name: "05", cmd: "05", color: 1 },
      { id: 6, name: "06", cmd: "06", color: 1 },
      { id: 7, name: "07", cmd: "07", color: 0 },
      { id: 8, name: "08", cmd: "08", color: 0 },
      { id: 9, name: "09", cmd: "09", color: 2 },
      { id: 10, name: "10", cmd: "10", color: 2 },
      { id: 11, name: "11", cmd: "11", color: 1 },
      { id: 12, name: "12", cmd: "12", color: 0 },
      { id: 13, name: "13", cmd: "13", color: 0 },
      { id: 14, name: "14", cmd: "14", color: 2 },
      { id: 15, name: "15", cmd: "15", color: 2 },
      { id: 16, name: "16", cmd: "16", color: 1 },
      { id: 17, name: "17", cmd: "17", color: 1 },
      { id: 18, name: "18", cmd: "18", color: 0 },
      { id: 19, name: "19", cmd: "19", color: 0 },
      { id: 20, name: "20", cmd: "20", color: 2 },
      { id: 21, name: "21", cmd: "21", color: 1 },
      { id: 22, name: "22", cmd: "22", color: 1 },
      { id: 23, name: "23", cmd: "23", color: 0 },
      { id: 24, name: "24", cmd: "24", color: 0 },
      { id: 25, name: "25", cmd: "25", color: 2 },
      { id: 26, name: "26", cmd: "26", color: 2 },
      { id: 27, name: "27", cmd: "27", color: 1 },
      { id: 28, name: "28", cmd: "28", color: 1 },
      { id: 29, name: "29", cmd: "29", color: 0 },
      { id: 30, name: "30", cmd: "30", color: 0 },
      { id: 31, name: "31", cmd: "31", color: 2 },
      { id: 32, name: "32", cmd: "32", color: 1 },
      { id: 33, name: "33", cmd: "33", color: 1 },
      { id: 34, name: "34", cmd: "34", color: 0 },
      { id: 35, name: "35", cmd: "35", color: 0 },
      { id: 36, name: "36", cmd: "36", color: 2 },
      { id: 37, name: "37", cmd: "37", color: 2 },
      { id: 38, name: "38", cmd: "38", color: 1 },
      { id: 39, name: "39", cmd: "39", color: 1 },
      { id: 40, name: "40", cmd: "40", color: 0 },
      { id: 41, name: "41", cmd: "41", color: 2 },
      { id: 42, name: "42", cmd: "42", color: 2 },
      { id: 43, name: "43", cmd: "43", color: 1 },
      { id: 44, name: "44", cmd: "44", color: 1 },
      { id: 45, name: "45", cmd: "45", color: 0 },
      { id: 46, name: "46", cmd: "46", color: 0 },
      { id: 47, name: "47", cmd: "47", color: 2 },
      { id: 48, name: "48", cmd: "48", color: 2 },
      { id: 49, name: "49", cmd: "49", color: 1 },
    ],
  },
  // 生肖
  502: {
    balls: [
      { id: 1, name: "鼠", cmd: "00" },
      { id: 2, name: "牛", cmd: "01" },
      { id: 3, name: "虎", cmd: "02" },
      { id: 4, name: "兔", cmd: "03" },
      { id: 5, name: "龙", cmd: "04" },
      { id: 6, name: "蛇", cmd: "05" },
      { id: 7, name: "马", cmd: "06" },
      { id: 8, name: "羊", cmd: "07" },
      { id: 9, name: "猴", cmd: "08" },
      { id: 10, name: "鸡", cmd: "09" },
      { id: 11, name: "狗", cmd: "10" },
      { id: 12, name: "猪", cmd: "11" },
    ],
  },
  // 组合
  503: {
    balls: [
      { id: 1, name: "大", cmd: "00" },
      { id: 2, name: "小", cmd: "01" },
      { id: 3, name: "单", cmd: "02" },
      { id: 4, name: "双", cmd: "03" },
      { id: 5, name: "大单", cmd: "04" },
      { id: 6, name: "小单", cmd: "05" },
      { id: 7, name: "大双", cmd: "06" },
      { id: 8, name: "小双", cmd: "07" },
    ],
  },
  // 波色
  504: {
    balls: [
      { id: 1, name: "红", cmd: "00", color: 0 },
      { id: 2, name: "绿", cmd: "01", color: 1 },
      { id: 3, name: "蓝", cmd: "02", color: 2 },
    ],
  },
  // 11选5
  601: {
    balls: [
      { id: 0, name: "0", cmd: "0" },
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
      { id: 7, name: "7", cmd: "7" },
      { id: 8, name: "8", cmd: "8" },
      { id: 9, name: "9", cmd: "9" },
      // { id: 10, name: "10", cmd: "10" },
      // { id: 11, name: "11", cmd: "11" },
    ],
  },
  /* 时时彩-经典彩 */
  // 整合盘-万/千/百/十/个
  701: {
    balls: [
      { id: 10, name: "大", cmd: "10" },
      { id: 11, name: "小", cmd: "11" },
      { id: 12, name: "单", cmd: "12" },
      { id: 13, name: "双", cmd: "13" },
      { id: 0, name: "0", cmd: "00" },
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
    ],
  },

  // 数字盘-万/千/百/十/个
  703: {
    balls: [
      { id: 0, name: "0", cmd: "00" },
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
    ],
  },
  // 两面盘-万/千/百/十/个
  704: {
    balls: [
      { id: 0, name: "大", cmd: "00" },
      { id: 1, name: "小", cmd: "01" },
      { id: 2, name: "单", cmd: "02" },
      { id: 3, name: "双", cmd: "03" },
    ],
  },

  // 前三/中三/后三
  705: {
    balls: [
      { id: 0, name: "豹子", cmd: "00" },
      { id: 1, name: "顺子", cmd: "01" },
      { id: 2, name: "对子", cmd: "02" },
      { id: 3, name: "半顺", cmd: "03" },
      { id: 4, name: "杂六", cmd: "04" },
    ],
  },
  // 总和龙虎
  706: {
    balls: [
      { id: 0, name: "总和大", cmd: "00" },
      { id: 1, name: "总和小", cmd: "01" },
      { id: 2, name: "总和单", cmd: "02" },
      { id: 3, name: "总和双", cmd: "03" },
      { id: 4, name: "龙", cmd: "04" },
      { id: 5, name: "虎", cmd: "05" },
      { id: 6, name: "和", cmd: "06" },
    ],
  },
  // 总和龙虎(副本: 隐藏龙虎和)
  707: {
    balls: [
      { id: 0, name: "总和大", cmd: "00" },
      { id: 1, name: "总和小", cmd: "01" },
      { id: 2, name: "总和单", cmd: "02" },
      { id: 3, name: "总和双", cmd: "03" },
      // { id: 4, name: '龙', cmd: '04' },
      // { id: 5, name: '虎', cmd: '05' },
      // { id: 6, name: '和', cmd: '06' }
    ],
  },

  /* 北京赛车-经典彩 */
  // 数字彩
  801: {
    balls: [
      { id: 1, name: "01", cmd: "01" },
      { id: 2, name: "02", cmd: "02" },
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
      { id: 7, name: "07", cmd: "07" },
      { id: 8, name: "08", cmd: "08" },
      { id: 9, name: "09", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
    ],
  },
  // 两面-冠亚
  802: {
    balls: [
      { id: 0, name: "冠亚大", cmd: "00" },
      { id: 1, name: "冠亚小", cmd: "01" },
      { id: 2, name: "冠亚单", cmd: "02" },
      { id: 3, name: "冠亚双", cmd: "03" },
    ],
  },
  // 两面-单双、龙虎
  803: {
    balls: [
      { id: 0, name: "大", cmd: "00" },
      { id: 1, name: "小", cmd: "01" },
      { id: 2, name: "单", cmd: "02" },
      { id: 3, name: "双", cmd: "03" },
        // 34741 【BUG】幸运飞艇，两面盘玩法不正确
      // { id: 4, name: "龙", cmd: "04" },
      // { id: 5, name: "虎", cmd: "05" },
    ],
  },
  // 两面-单双
  804: {
    balls: [
      { id: 0, name: "大", cmd: "00" },
      { id: 1, name: "小", cmd: "01" },
      { id: 2, name: "单", cmd: "02" },
      { id: 3, name: "双", cmd: "03" },
    ],
  },
  // 冠亚各
  805: {
    balls: [
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
      { id: 7, name: "07", cmd: "07" },
      { id: 8, name: "08", cmd: "08" },
      { id: 9, name: "09", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
      { id: 19, name: "19", cmd: "19" },
      { id: 20, name: "大", cmd: "20" },
      { id: 21, name: "小", cmd: "21" },
      { id: 22, name: "单", cmd: "22" },
      { id: 23, name: "双", cmd: "23" },
    ],
  },
  // 1～5名
  806: {
    balls: [
      { id: 1, name: "01", cmd: "01" },
      { id: 2, name: "02", cmd: "02" },
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
      { id: 7, name: "07", cmd: "07" },
      { id: 8, name: "08", cmd: "08" },
      { id: 9, name: "09", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "大", cmd: "11" },
      { id: 12, name: "小", cmd: "12" },
      { id: 13, name: "单", cmd: "13" },
      { id: 14, name: "双", cmd: "14" },
      { id: 15, name: "龙", cmd: "15" },
      { id: 16, name: "虎", cmd: "16" },
    ],
  },
  // 6～10名
  807: {
    balls: [
      { id: 1, name: "01", cmd: "01" },
      { id: 2, name: "02", cmd: "02" },
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
      { id: 7, name: "07", cmd: "07" },
      { id: 8, name: "08", cmd: "08" },
      { id: 9, name: "09", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "大", cmd: "11" },
      { id: 12, name: "小", cmd: "12" },
      { id: 13, name: "单", cmd: "13" },
      { id: 14, name: "双", cmd: "14" },
    ],
  },
  // 冠亚军组合
  808: {
    balls: [
      { id: 0, name: "1-2", cmd: "00" },
      { id: 1, name: "1-3", cmd: "01" },
      { id: 2, name: "1-4", cmd: "02" },
      { id: 3, name: "1-5", cmd: "03" },
      { id: 4, name: "1-6", cmd: "04" },
      { id: 5, name: "1-7", cmd: "05" },
      { id: 6, name: "1-8", cmd: "06" },
      { id: 7, name: "1-9", cmd: "07" },
      { id: 8, name: "1-10", cmd: "08" },
      { id: 9, name: "2-3", cmd: "09" },
      { id: 10, name: "2-4", cmd: "10" },
      { id: 11, name: "2-5", cmd: "11" },
      { id: 12, name: "2-6", cmd: "12" },
      { id: 13, name: "2-7", cmd: "13" },
      { id: 14, name: "2-8", cmd: "14" },
      { id: 15, name: "2-9", cmd: "15" },
      { id: 16, name: "2-10", cmd: "16" },
      { id: 17, name: "3-4", cmd: "17" },
      { id: 18, name: "3-5", cmd: "18" },
      { id: 19, name: "3-6", cmd: "19" },
      { id: 20, name: "3-7", cmd: "20" },
      { id: 21, name: "3-8", cmd: "21" },
      { id: 22, name: "3-9", cmd: "22" },
      { id: 23, name: "3-10", cmd: "23" },
      { id: 24, name: "4-5", cmd: "24" },
      { id: 25, name: "4-6", cmd: "25" },
      { id: 26, name: "4-7", cmd: "26" },
      { id: 27, name: "4-8", cmd: "27" },
      { id: 28, name: "4-9", cmd: "28" },
      { id: 29, name: "4-10", cmd: "29" },
      { id: 30, name: "5-6", cmd: "30" },
      { id: 31, name: "5-7", cmd: "31" },
      { id: 32, name: "5-8", cmd: "32" },
      { id: 33, name: "5-9", cmd: "33" },
      { id: 34, name: "5-10", cmd: "34" },
      { id: 35, name: "6-7", cmd: "35" },
      { id: 36, name: "6-8", cmd: "36" },
      { id: 37, name: "6-9", cmd: "37" },
      { id: 38, name: "6-10", cmd: "38" },
      { id: 39, name: "7-8", cmd: "39" },
      { id: 40, name: "7-9", cmd: "40" },
      { id: 41, name: "7-10", cmd: "41" },
      { id: 42, name: "8-9", cmd: "42" },
      { id: 43, name: "8-10", cmd: "43" },
      { id: 44, name: "9-10", cmd: "44" },
    ],
  },
  /* 香港彩 */
  // 号码
  901: {
    balls: [
      { id: 1, name: "1", cmd: "01" },
      { id: 2, name: "2", cmd: "02" },
      { id: 3, name: "3", cmd: "03" },
      { id: 4, name: "4", cmd: "04" },
      { id: 5, name: "5", cmd: "05" },
      { id: 6, name: "6", cmd: "06" },
      { id: 7, name: "7", cmd: "07" },
      { id: 8, name: "8", cmd: "08" },
      { id: 9, name: "9", cmd: "09" },
      { id: 10, name: "10", cmd: "10" },
      { id: 11, name: "11", cmd: "11" },
      { id: 12, name: "12", cmd: "12" },
      { id: 13, name: "13", cmd: "13" },
      { id: 14, name: "14", cmd: "14" },
      { id: 15, name: "15", cmd: "15" },
      { id: 16, name: "16", cmd: "16" },
      { id: 17, name: "17", cmd: "17" },
      { id: 18, name: "18", cmd: "18" },
      { id: 19, name: "19", cmd: "19" },
      { id: 20, name: "20", cmd: "20" },
      { id: 21, name: "21", cmd: "21" },
      { id: 22, name: "22", cmd: "22" },
      { id: 23, name: "23", cmd: "23" },
      { id: 24, name: "24", cmd: "24" },
      { id: 25, name: "25", cmd: "25" },
      { id: 26, name: "26", cmd: "26" },
      { id: 27, name: "27", cmd: "27" },
      { id: 28, name: "28", cmd: "28" },
      { id: 29, name: "29", cmd: "29" },
      { id: 30, name: "30", cmd: "30" },
      { id: 31, name: "31", cmd: "31" },
      { id: 32, name: "32", cmd: "32" },
      { id: 33, name: "33", cmd: "33" },
      { id: 34, name: "34", cmd: "34" },
      { id: 35, name: "35", cmd: "35" },
      { id: 36, name: "36", cmd: "36" },
      { id: 37, name: "37", cmd: "37" },
      { id: 38, name: "38", cmd: "38" },
      { id: 39, name: "39", cmd: "39" },
      { id: 40, name: "40", cmd: "40" },
      { id: 41, name: "41", cmd: "41" },
      { id: 42, name: "42", cmd: "42" },
      { id: 43, name: "43", cmd: "43" },
      { id: 44, name: "44", cmd: "44" },
      { id: 45, name: "45", cmd: "45" },
      { id: 46, name: "46", cmd: "46" },
      { id: 47, name: "47", cmd: "47" },
      { id: 48, name: "48", cmd: "48" },
      { id: 49, name: "49", cmd: "49" },
    ],
  },
  // 特码
  902: {
    balls: [
      { id: 50, name: "特大", cmd: "50" },
      { id: 51, name: "特小", cmd: "51" },
      { id: 52, name: "特单", cmd: "52" },
      { id: 53, name: "特双", cmd: "53" },
      { id: 54, name: "合大", cmd: "54" },
      { id: 55, name: "合小", cmd: "55" },
      { id: 56, name: "合单", cmd: "56" },
      { id: 57, name: "合双", cmd: "57" },
      { id: 58, name: "尾大", cmd: "58" },
      { id: 59, name: "尾小", cmd: "59" },
      { id: 60, name: "家禽", cmd: "60" },
      { id: 61, name: "野兽", cmd: "61" },
      { id: 62, name: "总大", cmd: "62" },
      { id: 63, name: "总小", cmd: "63" },
      { id: 64, name: "总单", cmd: "64" },
      { id: 65, name: "总双", cmd: "65" },
      { id: 66, name: "合尾大", cmd: "66" },
      { id: 67, name: "合尾小", cmd: "67" },
      { id: 68, name: "红波", cmd: "68" },
      { id: 69, name: "蓝波", cmd: "69" },
      { id: 70, name: "绿波", cmd: "70" },
      { id: 71, name: "大单", cmd: "71" },
      { id: 72, name: "大双", cmd: "72" },
      { id: 73, name: "小单", cmd: "73" },
      { id: 74, name: "小双", cmd: "74" },
      { id: 75, name: "奇", cmd: "75" },
      { id: 76, name: "奇偶和", cmd: "76" },
      { id: 77, name: "偶", cmd: "77" },
      { id: 78, name: "上", cmd: "78" },
      { id: 79, name: "上下和", cmd: "79" },
      { id: 80, name: "下", cmd: "80" },
    ],
  },
  // 色波
  903: {
    balls: [
      {
        id: 0,
        name: "红单",
        cmd: "00",
        subballs: [1, 7, 13, 19, 23, 29, 35, 45],
      },
      {
        id: 1,
        name: "红双",
        cmd: "01",
        subballs: [2, 8, 12, 18, 24, 30, 34, 40, 46],
      },
      {
        id: 2,
        name: "红大",
        cmd: "02",
        subballs: [29, 30, 34, 35, 40, 45, 46],
      },
      {
        id: 3,
        name: "红小",
        cmd: "03",
        subballs: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
      },
      {
        id: 4,
        name: "蓝单",
        cmd: "04",
        subballs: [3, 9, 15, 25, 31, 37, 41, 47],
      },
      {
        id: 5,
        name: "蓝双",
        cmd: "05",
        subballs: [4, 10, 14, 20, 26, 36, 42, 48],
      },
      {
        id: 6,
        name: "蓝大",
        cmd: "06",
        subballs: [25, 26, 31, 36, 37, 41, 42, 47, 48],
      },
      { id: 7, name: "蓝小", cmd: "07", subballs: [3, 4, 9, 10, 14, 15, 20] },
      {
        id: 8,
        name: "绿单",
        cmd: "08",
        subballs: [5, 11, 17, 21, 27, 33, 39, 43, 49],
      },
      { id: 9, name: "绿双", cmd: "09", subballs: [6, 16, 22, 28, 32, 38, 44] },
      {
        id: 10,
        name: "绿大",
        cmd: "10",
        subballs: [27, 28, 32, 33, 38, 39, 43, 44, 49],
      },
      { id: 11, name: "绿小", cmd: "11", subballs: [5, 6, 11, 16, 17, 21, 22] },
    ],
  },
  // 特肖/一肖/合肖/连肖
  904: {
    balls: [
      { id: 0, name: "鼠", cmd: "00" },
      { id: 1, name: "牛", cmd: "01" },
      { id: 2, name: "虎", cmd: "02" },
      { id: 3, name: "兔", cmd: "03" },
      { id: 4, name: "龙", cmd: "04" },
      { id: 5, name: "蛇", cmd: "05" },
      { id: 6, name: "马", cmd: "06" },
      { id: 7, name: "羊", cmd: "07" },
      { id: 8, name: "猴", cmd: "08" },
      { id: 9, name: "鸡", cmd: "09" },
      { id: 10, name: "狗", cmd: "10" },
      { id: 11, name: "猪", cmd: "11" },
    ],
  },
  // 正码1~6
  905: {
    balls: [
      { id: 0, name: "单", cmd: "0" },
      { id: 1, name: "双", cmd: "1" },
      { id: 2, name: "大", cmd: "2" },
      { id: 3, name: "小", cmd: "3" },
      { id: 4, name: "合单", cmd: "4" },
      { id: 5, name: "合双", cmd: "5" },
      { id: 6, name: "红波", cmd: "6" },
      { id: 7, name: "蓝波", cmd: "7" },
      { id: 8, name: "绿波", cmd: "8" },
    ],
  },
  // 七色波
  906: {
    balls: [
      { id: 12, name: "红波", cmd: "12" },
      { id: 13, name: "蓝波", cmd: "13" },
      { id: 14, name: "绿波", cmd: "14" },
      { id: 15, name: "和局", cmd: "15" },
    ],
  },
  // 连尾
  907: {
    balls: [
      { id: 0, name: "0", cmd: "0", subballs: [10, 20, 30, 40] },
      { id: 1, name: "1", cmd: "1", subballs: [1, 11, 21, 31, 41] },
      { id: 2, name: "2", cmd: "2", subballs: [2, 12, 22, 32, 42] },
      { id: 3, name: "3", cmd: "3", subballs: [3, 13, 23, 33, 43] },
      { id: 4, name: "4", cmd: "4", subballs: [4, 14, 24, 34, 44] },
      { id: 5, name: "5", cmd: "5", subballs: [5, 15, 25, 35, 45] },
      { id: 6, name: "6", cmd: "6", subballs: [6, 16, 26, 36, 46] },
      { id: 7, name: "7", cmd: "7", subballs: [7, 17, 27, 37, 47] },
      { id: 8, name: "8", cmd: "8", subballs: [8, 18, 28, 38, 48] },
      { id: 9, name: "9", cmd: "9", subballs: [9, 19, 29, 39, 49] },
    ],
  },

  // 龙虎
  908: {
    balls: [
      { id: 0, name: "龙", cmd: "0" },
      { id: 1, name: "虎", cmd: "1" },
    ],
  },
  // 尾数
  909: {
    balls: [
      { id: 0, name: "0尾", cmd: "0", subballs: [10, 20, 30, 40] },
      { id: 1, name: "1尾", cmd: "1", subballs: [1, 11, 21, 31, 41] },
      { id: 2, name: "2尾", cmd: "2", subballs: [2, 12, 22, 32, 42] },
      { id: 3, name: "3尾", cmd: "3", subballs: [3, 13, 23, 33, 43] },
      { id: 4, name: "4尾", cmd: "4", subballs: [4, 14, 24, 34, 44] },
      { id: 5, name: "5尾", cmd: "5", subballs: [5, 15, 25, 35, 45] },
      { id: 6, name: "6尾", cmd: "6", subballs: [6, 16, 26, 36, 46] },
      { id: 7, name: "7尾", cmd: "7", subballs: [7, 17, 27, 37, 47] },
      { id: 8, name: "8尾", cmd: "8", subballs: [8, 18, 28, 38, 48] },
      { id: 9, name: "9尾", cmd: "9", subballs: [9, 19, 29, 39, 49] },
    ],
  },
  // 连尾
  910: {
    balls: [
      { id: 0, name: "0尾", cmd: "0", subballs: [10, 20, 30, 40] },
      { id: 1, name: "1尾", cmd: "1", subballs: [1, 11, 21, 31, 41] },
      { id: 2, name: "2尾", cmd: "2", subballs: [2, 12, 22, 32, 42] },
      { id: 3, name: "3尾", cmd: "3", subballs: [3, 13, 23, 33, 43] },
      { id: 4, name: "4尾", cmd: "4", subballs: [4, 14, 24, 34, 44] },
      { id: 5, name: "5尾", cmd: "5", subballs: [5, 15, 25, 35, 45] },
      { id: 6, name: "6尾", cmd: "6", subballs: [6, 16, 26, 36, 46] },
      { id: 7, name: "7尾", cmd: "7", subballs: [7, 17, 27, 37, 47] },
      { id: 8, name: "8尾", cmd: "8", subballs: [8, 18, 28, 38, 48] },
      { id: 9, name: "9尾", cmd: "9", subballs: [9, 19, 29, 39, 49] },
    ],
  },
  // 一字组合
  911: {
    balls: [
      { id: 0, name: "组合0", cmd: "00" },
      { id: 1, name: "组合1", cmd: "01" },
      { id: 2, name: "组合2", cmd: "02" },
      { id: 3, name: "组合3", cmd: "03" },
      { id: 4, name: "组合4", cmd: "04" },
      { id: 5, name: "组合5", cmd: "05" },
      { id: 6, name: "组合6", cmd: "06" },
      { id: 7, name: "组合7", cmd: "07" },
      { id: 8, name: "组合8", cmd: "08" },
      { id: 9, name: "组合9", cmd: "09" },
    ],
  },
  // 大小单双质合
  912: {
    balls: [
      { id: 0, name: "佰大", cmd: "10" },
      { id: 1, name: "佰小", cmd: "11" },
      { id: 2, name: "佰单", cmd: "12" },
      { id: 3, name: "佰双", cmd: "13" },
      { id: 4, name: "佰质", cmd: "14" },
      { id: 5, name: "佰合", cmd: "15" },
      { id: 6, name: "拾大", cmd: "16" },
      { id: 7, name: "拾小", cmd: "17" },
      { id: 8, name: "拾单", cmd: "18" },
      { id: 9, name: "拾双", cmd: "19" },
      { id: 10, name: "拾质", cmd: "20" },
      { id: 11, name: "拾合", cmd: "21" },
      { id: 12, name: "个大", cmd: "22" },
      { id: 13, name: "个小", cmd: "23" },
      { id: 14, name: "个单", cmd: "24" },
      { id: 15, name: "个双", cmd: "25" },
      { id: 16, name: "个质", cmd: "26" },
      { id: 17, name: "个合", cmd: "27" },
      { id: 18, name: "佰拾单", cmd: "28" },
      { id: 19, name: "佰拾双", cmd: "29" },
      { id: 20, name: "佰个单", cmd: "30" },
      { id: 21, name: "佰个双", cmd: "31" },
      { id: 22, name: "佰拾单", cmd: "32" },
      { id: 23, name: "拾个双", cmd: "33" },
      { id: 24, name: "佰拾和尾数大", cmd: "34" },
      { id: 25, name: "佰拾和尾数小", cmd: "35" },
      { id: 26, name: "佰拾和尾数质", cmd: "36" },
      { id: 27, name: "佰拾和尾数合", cmd: "37" },
      { id: 28, name: "佰个和尾数大", cmd: "38" },
      { id: 29, name: "佰个和尾数小", cmd: "39" },
      { id: 30, name: "佰个和尾数质", cmd: "40" },
      { id: 31, name: "佰个和尾数合", cmd: "41" },
      { id: 32, name: "拾个和尾数大", cmd: "42" },
      { id: 33, name: "拾个和尾数小", cmd: "43" },
      { id: 34, name: "拾个和尾数质", cmd: "44" },
      { id: 35, name: "拾个和尾数合", cmd: "45" },
      { id: 36, name: "佰拾个和数大", cmd: "46" },
      { id: 37, name: "佰拾个和数小", cmd: "47" },
      { id: 38, name: "佰拾个和数单", cmd: "48" },
      { id: 39, name: "佰拾个和数双", cmd: "49" },
      { id: 40, name: "佰拾个和尾数大", cmd: "50" },
      { id: 41, name: "佰拾个和尾数小", cmd: "51" },
      { id: 42, name: "佰拾个和尾数质", cmd: "52" },
      { id: 43, name: "佰拾个和尾数合", cmd: "53" },
    ],
  },
  // 二字组合
  913: {
    balls: [
      { id: 0, name: "种类00", cmd: "00" },
      { id: 1, name: "种类01", cmd: "01" },
      { id: 2, name: "种类02", cmd: "02" },
      { id: 3, name: "种类03", cmd: "03" },
      { id: 4, name: "种类04", cmd: "04" },
      { id: 5, name: "种类05", cmd: "05" },
      { id: 6, name: "种类06", cmd: "06" },
      { id: 7, name: "种类07", cmd: "07" },
      { id: 8, name: "种类08", cmd: "08" },
      { id: 9, name: "种类09", cmd: "09" },
      { id: 10, name: "种类10", cmd: "10" },
      { id: 11, name: "种类11", cmd: "11" },
      { id: 12, name: "种类12", cmd: "12" },
      { id: 13, name: "种类13", cmd: "13" },
      { id: 14, name: "种类14", cmd: "14" },
      { id: 15, name: "种类15", cmd: "15" },
      { id: 16, name: "种类16", cmd: "16" },
      { id: 17, name: "种类17", cmd: "17" },
      { id: 18, name: "种类18", cmd: "18" },
      { id: 19, name: "种类19", cmd: "19" },
      { id: 20, name: "种类20", cmd: "20" },
      { id: 21, name: "种类21", cmd: "21" },
      { id: 22, name: "种类22", cmd: "22" },
      { id: 23, name: "种类23", cmd: "23" },
      { id: 24, name: "种类24", cmd: "24" },
      { id: 25, name: "种类25", cmd: "25" },
      { id: 26, name: "种类26", cmd: "26" },
      { id: 27, name: "种类27", cmd: "27" },
      { id: 28, name: "种类28", cmd: "28" },
      { id: 29, name: "种类29", cmd: "29" },
      { id: 30, name: "种类30", cmd: "30" },
      { id: 31, name: "种类31", cmd: "31" },
      { id: 32, name: "种类32", cmd: "32" },
      { id: 33, name: "种类33", cmd: "33" },
      { id: 34, name: "种类34", cmd: "34" },
      { id: 35, name: "种类35", cmd: "35" },
      { id: 36, name: "种类36", cmd: "36" },
      { id: 37, name: "种类37", cmd: "37" },
      { id: 38, name: "种类38", cmd: "38" },
      { id: 39, name: "种类39", cmd: "39" },
      { id: 40, name: "种类40", cmd: "40" },
      { id: 41, name: "种类41", cmd: "41" },
      { id: 42, name: "种类42", cmd: "42" },
      { id: 43, name: "种类43", cmd: "43" },
      { id: 44, name: "种类44", cmd: "44" },
      { id: 45, name: "种类45", cmd: "45" },
      { id: 46, name: "种类46", cmd: "46" },
      { id: 47, name: "种类47", cmd: "47" },
      { id: 48, name: "种类48", cmd: "48" },
      { id: 49, name: "种类49", cmd: "49" },
      { id: 50, name: "种类50", cmd: "50" },
      { id: 51, name: "种类51", cmd: "51" },
      { id: 52, name: "种类52", cmd: "52" },
      { id: 53, name: "种类53", cmd: "53" },
      { id: 54, name: "种类54", cmd: "54" },
      { id: 55, name: "种类55", cmd: "55" },
      { id: 56, name: "种类56", cmd: "56" },
      { id: 57, name: "种类57", cmd: "57" },
      { id: 58, name: "种类58", cmd: "58" },
      { id: 59, name: "种类59", cmd: "59" },
      { id: 60, name: "种类60", cmd: "60" },
      { id: 61, name: "种类61", cmd: "61" },
      { id: 62, name: "种类62", cmd: "62" },
      { id: 63, name: "种类63", cmd: "63" },
      { id: 64, name: "种类64", cmd: "64" },
      { id: 65, name: "种类65", cmd: "65" },
      { id: 66, name: "种类66", cmd: "66" },
      { id: 67, name: "种类67", cmd: "67" },
      { id: 68, name: "种类68", cmd: "68" },
      { id: 69, name: "种类69", cmd: "69" },
      { id: 70, name: "种类70", cmd: "70" },
      { id: 71, name: "种类71", cmd: "71" },
      { id: 72, name: "种类72", cmd: "72" },
      { id: 73, name: "种类73", cmd: "73" },
      { id: 74, name: "种类74", cmd: "74" },
      { id: 75, name: "种类75", cmd: "75" },
      { id: 76, name: "种类76", cmd: "76" },
      { id: 77, name: "种类77", cmd: "77" },
      { id: 78, name: "种类78", cmd: "78" },
      { id: 79, name: "种类79", cmd: "79" },
      { id: 80, name: "种类80", cmd: "80" },
      { id: 81, name: "种类81", cmd: "81" },
      { id: 82, name: "种类82", cmd: "82" },
      { id: 83, name: "种类83", cmd: "83" },
      { id: 84, name: "种类84", cmd: "84" },
      { id: 85, name: "种类85", cmd: "85" },
      { id: 86, name: "种类86", cmd: "86" },
      { id: 87, name: "种类87", cmd: "87" },
      { id: 88, name: "种类88", cmd: "88" },
      { id: 89, name: "种类89", cmd: "89" },
      { id: 90, name: "种类90", cmd: "90" },
      { id: 91, name: "种类91", cmd: "91" },
      { id: 92, name: "种类92", cmd: "92" },
      { id: 93, name: "种类93", cmd: "93" },
      { id: 94, name: "种类94", cmd: "94" },
      { id: 95, name: "种类95", cmd: "95" },
      { id: 96, name: "种类96", cmd: "96" },
      { id: 97, name: "种类97", cmd: "97" },
      { id: 98, name: "种类98", cmd: "98" },
      { id: 99, name: "种类99", cmd: "99" },
    ],
  },
  // 三字组合
  914: {
    balls: [
      { id: 0, name: "种类000", cmd: "000" },
      { id: 1, name: "种类001", cmd: "001" },
      { id: 2, name: "种类002", cmd: "002" },
      { id: 3, name: "种类003", cmd: "003" },
      { id: 4, name: "种类004", cmd: "004" },
      { id: 5, name: "种类005", cmd: "005" },
      { id: 6, name: "种类006", cmd: "006" },
      { id: 7, name: "种类007", cmd: "007" },
      { id: 8, name: "种类008", cmd: "008" },
      { id: 9, name: "种类009", cmd: "009" },
      { id: 11, name: "种类011", cmd: "011" },
      { id: 12, name: "种类012", cmd: "012" },
      { id: 13, name: "种类013", cmd: "013" },
      { id: 14, name: "种类014", cmd: "014" },
      { id: 15, name: "种类015", cmd: "015" },
      { id: 16, name: "种类016", cmd: "016" },
      { id: 17, name: "种类017", cmd: "017" },
      { id: 18, name: "种类018", cmd: "018" },
      { id: 19, name: "种类019", cmd: "019" },
      { id: 22, name: "种类022", cmd: "022" },
      { id: 23, name: "种类023", cmd: "023" },
      { id: 24, name: "种类024", cmd: "024" },
      { id: 25, name: "种类025", cmd: "025" },
      { id: 26, name: "种类026", cmd: "026" },
      { id: 27, name: "种类027", cmd: "027" },
      { id: 28, name: "种类028", cmd: "028" },
      { id: 29, name: "种类029", cmd: "029" },
      { id: 33, name: "种类033", cmd: "033" },
      { id: 34, name: "种类034", cmd: "034" },
      { id: 35, name: "种类035", cmd: "035" },
      { id: 36, name: "种类036", cmd: "036" },
      { id: 37, name: "种类037", cmd: "037" },
      { id: 38, name: "种类038", cmd: "038" },
      { id: 39, name: "种类039", cmd: "039" },
      { id: 44, name: "种类044", cmd: "044" },
      { id: 45, name: "种类045", cmd: "045" },
      { id: 46, name: "种类046", cmd: "046" },
      { id: 47, name: "种类047", cmd: "047" },
      { id: 48, name: "种类048", cmd: "048" },
      { id: 49, name: "种类049", cmd: "049" },
      { id: 55, name: "种类055", cmd: "055" },
      { id: 56, name: "种类056", cmd: "056" },
      { id: 57, name: "种类057", cmd: "057" },
      { id: 58, name: "种类058", cmd: "058" },
      { id: 59, name: "种类059", cmd: "059" },
      { id: 66, name: "种类066", cmd: "066" },
      { id: 67, name: "种类067", cmd: "067" },
      { id: 68, name: "种类068", cmd: "068" },
      { id: 69, name: "种类069", cmd: "069" },
      { id: 77, name: "种类077", cmd: "077" },
      { id: 78, name: "种类078", cmd: "078" },
      { id: 79, name: "种类079", cmd: "079" },
      { id: 88, name: "种类088", cmd: "088" },
      { id: 89, name: "种类089", cmd: "089" },
      { id: 99, name: "种类099", cmd: "099" },
      { id: 111, name: "种类111", cmd: "111" },
      { id: 112, name: "种类112", cmd: "112" },
      { id: 113, name: "种类113", cmd: "113" },
      { id: 114, name: "种类114", cmd: "114" },
      { id: 115, name: "种类115", cmd: "115" },
      { id: 116, name: "种类116", cmd: "116" },
      { id: 117, name: "种类117", cmd: "117" },
      { id: 118, name: "种类118", cmd: "118" },
      { id: 119, name: "种类119", cmd: "119" },
      { id: 122, name: "种类122", cmd: "122" },
      { id: 123, name: "种类123", cmd: "123" },
      { id: 124, name: "种类124", cmd: "124" },
      { id: 125, name: "种类125", cmd: "125" },
      { id: 126, name: "种类126", cmd: "126" },
      { id: 127, name: "种类127", cmd: "127" },
      { id: 128, name: "种类128", cmd: "128" },
      { id: 129, name: "种类129", cmd: "129" },
      { id: 133, name: "种类133", cmd: "133" },
      { id: 134, name: "种类134", cmd: "134" },
      { id: 135, name: "种类135", cmd: "135" },
      { id: 136, name: "种类136", cmd: "136" },
      { id: 137, name: "种类137", cmd: "137" },
      { id: 138, name: "种类138", cmd: "138" },
      { id: 139, name: "种类139", cmd: "139" },
      { id: 144, name: "种类144", cmd: "144" },
      { id: 145, name: "种类145", cmd: "145" },
      { id: 146, name: "种类146", cmd: "146" },
      { id: 147, name: "种类147", cmd: "147" },
      { id: 148, name: "种类148", cmd: "148" },
      { id: 149, name: "种类149", cmd: "149" },
      { id: 155, name: "种类155", cmd: "155" },
      { id: 156, name: "种类156", cmd: "156" },
      { id: 157, name: "种类157", cmd: "157" },
      { id: 158, name: "种类158", cmd: "158" },
      { id: 159, name: "种类159", cmd: "159" },
      { id: 166, name: "种类166", cmd: "166" },
      { id: 167, name: "种类167", cmd: "167" },
      { id: 168, name: "种类168", cmd: "168" },
      { id: 169, name: "种类169", cmd: "169" },
      { id: 177, name: "种类177", cmd: "177" },
      { id: 178, name: "种类178", cmd: "178" },
      { id: 179, name: "种类179", cmd: "179" },
      { id: 188, name: "种类188", cmd: "188" },
      { id: 189, name: "种类189", cmd: "189" },
      { id: 199, name: "种类199", cmd: "199" },
      { id: 222, name: "种类222", cmd: "222" },
      { id: 223, name: "种类223", cmd: "223" },
      { id: 224, name: "种类224", cmd: "224" },
      { id: 225, name: "种类225", cmd: "225" },
      { id: 226, name: "种类226", cmd: "226" },
      { id: 227, name: "种类227", cmd: "227" },
      { id: 228, name: "种类228", cmd: "228" },
      { id: 229, name: "种类229", cmd: "229" },
      { id: 233, name: "种类233", cmd: "233" },
      { id: 234, name: "种类234", cmd: "234" },
      { id: 235, name: "种类235", cmd: "235" },
      { id: 236, name: "种类236", cmd: "236" },
      { id: 237, name: "种类237", cmd: "237" },
      { id: 238, name: "种类238", cmd: "238" },
      { id: 239, name: "种类239", cmd: "239" },
      { id: 244, name: "种类244", cmd: "244" },
      { id: 245, name: "种类245", cmd: "245" },
      { id: 246, name: "种类246", cmd: "246" },
      { id: 247, name: "种类247", cmd: "247" },
      { id: 248, name: "种类248", cmd: "248" },
      { id: 249, name: "种类249", cmd: "249" },
      { id: 255, name: "种类255", cmd: "255" },
      { id: 256, name: "种类256", cmd: "256" },
      { id: 257, name: "种类257", cmd: "257" },
      { id: 258, name: "种类258", cmd: "258" },
      { id: 259, name: "种类259", cmd: "259" },
      { id: 266, name: "种类266", cmd: "266" },
      { id: 267, name: "种类267", cmd: "267" },
      { id: 268, name: "种类268", cmd: "268" },
      { id: 269, name: "种类269", cmd: "269" },
      { id: 277, name: "种类277", cmd: "277" },
      { id: 278, name: "种类278", cmd: "278" },
      { id: 279, name: "种类279", cmd: "279" },
      { id: 288, name: "种类288", cmd: "288" },
      { id: 289, name: "种类289", cmd: "289" },
      { id: 299, name: "种类299", cmd: "299" },
      { id: 333, name: "种类333", cmd: "333" },
      { id: 334, name: "种类334", cmd: "334" },
      { id: 335, name: "种类335", cmd: "335" },
      { id: 336, name: "种类336", cmd: "336" },
      { id: 337, name: "种类337", cmd: "337" },
      { id: 338, name: "种类338", cmd: "338" },
      { id: 339, name: "种类339", cmd: "339" },
      { id: 344, name: "种类344", cmd: "344" },
      { id: 345, name: "种类345", cmd: "345" },
      { id: 346, name: "种类346", cmd: "346" },
      { id: 347, name: "种类347", cmd: "347" },
      { id: 348, name: "种类348", cmd: "348" },
      { id: 349, name: "种类349", cmd: "349" },
      { id: 355, name: "种类355", cmd: "355" },
      { id: 356, name: "种类356", cmd: "356" },
      { id: 357, name: "种类357", cmd: "357" },
      { id: 358, name: "种类358", cmd: "358" },
      { id: 359, name: "种类359", cmd: "359" },
      { id: 366, name: "种类366", cmd: "366" },
      { id: 367, name: "种类367", cmd: "367" },
      { id: 368, name: "种类368", cmd: "368" },
      { id: 369, name: "种类369", cmd: "369" },
      { id: 377, name: "种类377", cmd: "377" },
      { id: 378, name: "种类378", cmd: "378" },
      { id: 379, name: "种类379", cmd: "379" },
      { id: 388, name: "种类388", cmd: "388" },
      { id: 389, name: "种类389", cmd: "389" },
      { id: 399, name: "种类399", cmd: "399" },
      { id: 444, name: "种类444", cmd: "444" },
      { id: 445, name: "种类445", cmd: "445" },
      { id: 446, name: "种类446", cmd: "446" },
      { id: 447, name: "种类447", cmd: "447" },
      { id: 448, name: "种类448", cmd: "448" },
      { id: 449, name: "种类449", cmd: "449" },
      { id: 455, name: "种类455", cmd: "455" },
      { id: 456, name: "种类456", cmd: "456" },
      { id: 457, name: "种类457", cmd: "457" },
      { id: 458, name: "种类458", cmd: "458" },
      { id: 459, name: "种类459", cmd: "459" },
      { id: 466, name: "种类466", cmd: "466" },
      { id: 467, name: "种类467", cmd: "467" },
      { id: 468, name: "种类468", cmd: "468" },
      { id: 469, name: "种类469", cmd: "469" },
      { id: 477, name: "种类477", cmd: "477" },
      { id: 478, name: "种类478", cmd: "478" },
      { id: 479, name: "种类479", cmd: "479" },
      { id: 488, name: "种类488", cmd: "488" },
      { id: 489, name: "种类489", cmd: "489" },
      { id: 499, name: "种类499", cmd: "499" },
      { id: 555, name: "种类555", cmd: "555" },
      { id: 556, name: "种类556", cmd: "556" },
      { id: 557, name: "种类557", cmd: "557" },
      { id: 558, name: "种类558", cmd: "558" },
      { id: 559, name: "种类559", cmd: "559" },
      { id: 566, name: "种类566", cmd: "566" },
      { id: 567, name: "种类567", cmd: "567" },
      { id: 568, name: "种类568", cmd: "568" },
      { id: 569, name: "种类569", cmd: "569" },
      { id: 577, name: "种类577", cmd: "577" },
      { id: 578, name: "种类578", cmd: "578" },
      { id: 579, name: "种类579", cmd: "579" },
      { id: 588, name: "种类588", cmd: "588" },
      { id: 589, name: "种类589", cmd: "589" },
      { id: 599, name: "种类599", cmd: "599" },
      { id: 666, name: "种类666", cmd: "666" },
      { id: 667, name: "种类667", cmd: "667" },
      { id: 668, name: "种类668", cmd: "668" },
      { id: 669, name: "种类669", cmd: "669" },
      { id: 677, name: "种类677", cmd: "677" },
      { id: 678, name: "种类678", cmd: "678" },
      { id: 679, name: "种类679", cmd: "679" },
      { id: 688, name: "种类688", cmd: "688" },
      { id: 689, name: "种类689", cmd: "689" },
      { id: 699, name: "种类699", cmd: "699" },
      { id: 777, name: "种类777", cmd: "777" },
      { id: 778, name: "种类778", cmd: "778" },
      { id: 779, name: "种类779", cmd: "779" },
      { id: 788, name: "种类788", cmd: "788" },
      { id: 789, name: "种类789", cmd: "789" },
      { id: 799, name: "种类799", cmd: "799" },
      { id: 888, name: "种类888", cmd: "888" },
      { id: 889, name: "种类889", cmd: "889" },
      { id: 899, name: "种类899", cmd: "899" },
      { id: 999, name: "种类999", cmd: "999" },
    ],
  },
  // 三字组合
  915: {
    balls: [
      { id: 0, name: "佰0", cmd: "00" },
      { id: 1, name: "佰1", cmd: "01" },
      { id: 2, name: "佰2", cmd: "02" },
      { id: 3, name: "佰3", cmd: "03" },
      { id: 4, name: "佰4", cmd: "04" },
      { id: 5, name: "佰5", cmd: "05" },
      { id: 6, name: "佰6", cmd: "06" },
      { id: 7, name: "佰7", cmd: "07" },
      { id: 8, name: "佰8", cmd: "08" },
      { id: 9, name: "佰9", cmd: "09" },
      { id: 10, name: "拾0", cmd: "10" },
      { id: 11, name: "拾1", cmd: "11" },
      { id: 12, name: "拾2", cmd: "12" },
      { id: 13, name: "拾3", cmd: "13" },
      { id: 14, name: "拾4", cmd: "14" },
      { id: 15, name: "拾5", cmd: "15" },
      { id: 16, name: "拾6", cmd: "16" },
      { id: 17, name: "拾7", cmd: "17" },
      { id: 18, name: "拾8", cmd: "18" },
      { id: 19, name: "拾9", cmd: "19" },
      { id: 20, name: "个0", cmd: "20" },
      { id: 21, name: "个1", cmd: "21" },
      { id: 22, name: "个2", cmd: "22" },
      { id: 23, name: "个3", cmd: "23" },
      { id: 24, name: "个4", cmd: "24" },
      { id: 25, name: "个5", cmd: "25" },
      { id: 26, name: "个6", cmd: "26" },
      { id: 27, name: "个7", cmd: "27" },
      { id: 28, name: "个8", cmd: "28" },
      { id: 29, name: "个9", cmd: "29" },
    ],
  },
  // 二字定位 - 佰
  916: {
    balls: [
      { id: 0, name: "0", cmd: "0" },
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
      { id: 7, name: "7", cmd: "7" },
      { id: 8, name: "8", cmd: "8" },
      { id: 9, name: "9", cmd: "9" },
    ],
  },
  // 二字定位 - 拾
  1005: {
    balls: [
      { id: 10, name: "0", cmd: "0" },
      { id: 11, name: "1", cmd: "1" },
      { id: 12, name: "2", cmd: "2" },
      { id: 13, name: "3", cmd: "3" },
      { id: 14, name: "4", cmd: "4" },
      { id: 15, name: "5", cmd: "5" },
      { id: 16, name: "6", cmd: "6" },
      { id: 17, name: "7", cmd: "7" },
      { id: 18, name: "8", cmd: "8" },
      { id: 19, name: "9", cmd: "9" },
    ],
  },
  // 二字定位 - 个
  1006: {
    balls: [
      { id: 20, name: "0", cmd: "0" },
      { id: 21, name: "1", cmd: "1" },
      { id: 22, name: "2", cmd: "2" },
      { id: 23, name: "3", cmd: "3" },
      { id: 24, name: "4", cmd: "4" },
      { id: 25, name: "5", cmd: "5" },
      { id: 26, name: "6", cmd: "6" },
      { id: 27, name: "7", cmd: "7" },
      { id: 28, name: "8", cmd: "8" },
      { id: 29, name: "9", cmd: "9" },
    ],
  },
  // 二字和数
  917: {
    balls: [
      { id: 0, name: "佰拾和数0~4", cmd: "00" },
      { id: 1, name: "佰拾和数5", cmd: "01" },
      { id: 2, name: "佰拾和数6", cmd: "02" },
      { id: 3, name: "佰拾和数7", cmd: "03" },
      { id: 4, name: "佰拾和数8", cmd: "04" },
      { id: 5, name: "佰拾和数9", cmd: "05" },
      { id: 6, name: "佰拾和数10", cmd: "06" },
      { id: 7, name: "佰拾和数11", cmd: "07" },
      { id: 8, name: "佰拾和数12", cmd: "08" },
      { id: 9, name: "佰拾和数13", cmd: "09" },
      { id: 10, name: "佰拾和数14~18", cmd: "10" },
      { id: 11, name: "佰拾和数尾数", cmd: "11" },
      { id: 12, name: "佰个和数0~4", cmd: "12" },
      { id: 13, name: "佰个和数5", cmd: "13" },
      { id: 14, name: "佰个和数6", cmd: "14" },
      { id: 15, name: "佰个和数7", cmd: "15" },
      { id: 16, name: "佰个和数8", cmd: "16" },
      { id: 17, name: "佰个和数9", cmd: "17" },
      { id: 18, name: "佰个和数10", cmd: "18" },
      { id: 19, name: "佰个和数11", cmd: "19" },
      { id: 20, name: "佰个和数12", cmd: "20" },
      { id: 21, name: "佰个和数13", cmd: "21" },
      { id: 22, name: "佰个和数14~18", cmd: "22" },
      { id: 23, name: "佰个和数尾数", cmd: "23" },
      { id: 24, name: "拾个和数0~4", cmd: "24" },
      { id: 25, name: "拾个和数5", cmd: "25" },
      { id: 26, name: "拾个和数6", cmd: "26" },
      { id: 27, name: "拾个和数7", cmd: "27" },
      { id: 28, name: "拾个和数8", cmd: "28" },
      { id: 29, name: "拾个和数9", cmd: "29" },
      { id: 30, name: "拾个和数10", cmd: "30" },
      { id: 31, name: "拾个和数11", cmd: "31" },
      { id: 32, name: "拾个和数12", cmd: "32" },
      { id: 33, name: "拾个和数13", cmd: "33" },
      { id: 34, name: "拾个和数14~18", cmd: "34" },
      { id: 35, name: "拾个和数尾数", cmd: "35" },
    ],
  },
  // 三字和数
  918: {
    balls: [
      { id: 0, name: "三字和数0~6", cmd: "00" },
      { id: 1, name: "三字和数7", cmd: "01" },
      { id: 2, name: "三字和数8", cmd: "02" },
      { id: 3, name: "三字和数9", cmd: "03" },
      { id: 4, name: "三字和数10", cmd: "04" },
      { id: 5, name: "三字和数11", cmd: "05" },
      { id: 6, name: "三字和数12", cmd: "06" },
      { id: 7, name: "三字和数13", cmd: "07" },
      { id: 8, name: "三字和数14", cmd: "08" },
      { id: 9, name: "三字和数15", cmd: "09" },
      { id: 10, name: "三字和数16", cmd: "10" },
      { id: 11, name: "三字和数17", cmd: "11" },
      { id: 12, name: "三字和数18", cmd: "12" },
      { id: 13, name: "三字和数19", cmd: "13" },
      { id: 14, name: "三字和数20", cmd: "14" },
      { id: 15, name: "三字和数21~27", cmd: "15" },
      { id: 16, name: "三字和数尾数", cmd: "16" },
    ],
  },
  // 三字和数
  919: {
    balls: [
      { id: 0, name: "跨度0", cmd: "00" },
      { id: 1, name: "跨度1", cmd: "01" },
      { id: 2, name: "跨度2", cmd: "02" },
      { id: 3, name: "跨度3", cmd: "03" },
      { id: 4, name: "跨度4", cmd: "04" },
      { id: 5, name: "跨度5", cmd: "05" },
      { id: 6, name: "跨度6", cmd: "06" },
      { id: 7, name: "跨度7", cmd: "07" },
      { id: 8, name: "跨度8", cmd: "08" },
      { id: 9, name: "跨度9", cmd: "09" },
    ],
  },
  // 选颜色
  1001: {
    balls: [{ id: 1, name: "绿色", cmd: "1" }],
  },
  // 选颜色
  1002: {
    balls: [{ id: 2, name: "红色", cmd: "2" }],
  },
  // 选颜色
  1003: {
    balls: [{ id: 3, name: "紫色", cmd: "3" }],
  },
  // 选号码
  1004: {
    balls: [
      { id: 0, name: "0", cmd: "0" },
      { id: 1, name: "1", cmd: "1" },
      { id: 2, name: "2", cmd: "2" },
      { id: 3, name: "3", cmd: "3" },
      { id: 4, name: "4", cmd: "4" },
      { id: 5, name: "5", cmd: "5" },
      { id: 6, name: "6", cmd: "6" },
      { id: 7, name: "7", cmd: "7" },
      { id: 8, name: "8", cmd: "8" },
      { id: 9, name: "9", cmd: "9" },
    ],
  },

  // 动物彩
  1101: {
    balls: [
      { id: 1, name: "01", cmd: "01" },
      { id: 2, name: "02", cmd: "02" },
      { id: 3, name: "03", cmd: "03" },
      { id: 4, name: "04", cmd: "04" },
      { id: 5, name: "05", cmd: "05" },
      { id: 6, name: "06", cmd: "06" },
    ],
  },

};
