import config from '../config'
import calendar from './calendar'

export default class helperHK {
  static context = null

  // 获取开奖号码的
  static getAwardBox(mainPlayid, wincodes, item = {} as any) {
    let awardbox = []
    if (!wincodes || wincodes.length === 0) return awardbox
    let d = new Date()
    d.setTime(item.begin_time)
    let zodiac = this.generateYearNumZodiac(d)
    for (let i = 0; i < wincodes.length; i++) {
      let code = Number(wincodes[i])
      awardbox.push(zodiac[String(code)])
    }
    return awardbox
  }

  // 根据号码获取球颜色 红：1， 蓝：2， 绿：3
  static getBallColor(ball) {
    let balls = [1, 1, 2, 2, 3, 3, 1, 1, 2, 2, 3, 1, 1, 2, 2, 3, 3, 1, 1, 2, 3, 3, 1, 1, 2, 2, 3, 3, 1, 1, 2, 3, 3, 1, 1, 2, 2, 3, 3, 1, 2, 2, 3, 3, 1, 1, 2, 2, 3]
    return balls[ball - 1]
  }

  static generateYearZodiacWithTimestamp(timestamp, playid = -1) {
    let yearZodiac = {}
    if (timestamp) {
      return this.generateYearZodiac(new Date(), playid)
    }
    return yearZodiac
  }

  // 生成指定日期的所有生肖对应的号码
  // @param date Date
  static generateYearZodiac(date, playid = -1) {
    // 获取农历年份
    let lunarYear = ''
    let lunarYearObj = calendar.solar2lunar(date.getFullYear(), date.getMonth() + 1, date.getDate())
    if (lunarYearObj) {
      // @ts-ignore
      lunarYear = lunarYearObj.lYear
    }
    let yearZodiac = {}

    for (let i = 0; i < 12; i++) {
      yearZodiac[i] = []
    }

    let beginIndex = (parseInt(lunarYear) - 4) % 12

    let first = playid.toString().substring(0, playid.toString().length - 3)
    let isHexiao = playid >= Number(`${first}032`) && playid <= Number(`${first}053`)

    let filter = isHexiao
    for (let i = 1; i <= 49; i++) {
      let zodiacIndex = beginIndex % 12
      if (!(filter && yearZodiac[zodiacIndex].length === 4)) {
        yearZodiac[zodiacIndex].push(i)
      }

      beginIndex--
      if (beginIndex < 0) {
        beginIndex += 12
      }
    }
    return yearZodiac
  }

  // 获取开奖公告对应期的生肖信息
  static generateYearNumWithHistoryItem(item) {
    let d = new Date()
    d.setTime(item.begin_time)
    return this.generateYearNumZodiac(d)
  }

  // 生成每个号码对应的生肖
  static generateYearNumZodiac(date) {
    // 获取农历年份
    let lunarYear = ''
    let lunarYearObj = calendar.solar2lunar(date.getFullYear(), date.getMonth() + 1, date.getDate())
    if (lunarYearObj) {
      // @ts-ignore
      lunarYear = lunarYearObj.lYear
    }
    let numZodiac = {}
    let zodiacs = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
    let beginIndex = (parseInt(lunarYear) - 4) % 12

    for (let i = 1; i <= 49; i++) {
      numZodiac[i] = zodiacs[beginIndex % 12]
      beginIndex--
      if (beginIndex < 0) {
        beginIndex += 12
      }
    }
    return numZodiac
  }

  // 生成六合彩生肖信息 用于六合彩玩法说明等
  static get12MarkSixInfo(date) {
    let numZodiac = this.generateYearNumZodiac(date)
    let zodiacs = '鼠牛虎兔龙蛇马羊猴鸡狗猪'
    let zodiacsArr = (zodiacs.substring(zodiacs.indexOf(numZodiac[1]), zodiacs.length) + zodiacs.substring(0, zodiacs.indexOf(numZodiac[1]))).split('')
    let obj = {
      current: numZodiac[1],
      animalSort: zodiacsArr.join(' > '),
      animalTextArr: [],
      animalObjArr: []
    }
    zodiacsArr.map(item => {
      let text = ''
      for (let i in numZodiac) {
        if (numZodiac[i] === item) {
          text += i + ', '
        }
      }
      obj.animalTextArr.push(item + ': ' + text.substring(0, text.length - 2))
      obj.animalObjArr.push({animal: item, text: text.substring(0, text.length - 2)})
    })
    return obj
  }

  // 获得对碰组合
  static getPairBalls(playId, selectedIds) {
    let play = config.getPlayInfo(playId)
    let ballinfo = config.getBallInfo(playId)
    let pairballs = []
    if (play.note_tag === '2xiaopen') {
      let calendar = helperHK.generateYearZodiac(new Date(), playId)
      let a = calendar[Number(ballinfo.balls[selectedIds[0]].cmd)]
      let b = calendar[Number(ballinfo.balls[selectedIds[1]].cmd)]
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          let arr = []
          let str1 = a[i] < 10 ? ('0' + a[i]) : String(a[i])
          let str2 = b[j] < 10 ? ('0' + b[j]) : String(b[j])
          arr.push(str1)
          arr.push(str2)
          pairballs.push(arr)
        }
      }
    } else if (play.note_tag === '2weipen') {
      let a = ballinfo.balls[selectedIds[0]].subballs
      let b = ballinfo.balls[selectedIds[1]].subballs
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          let arr = []
          let str1 = a[i] < 10 ? ('0' + a[i]) : String(a[i])
          let str2 = b[j] < 10 ? ('0' + b[j]) : String(b[j])
          arr.push(str1)
          arr.push(str2)
          pairballs.push(arr)
        }
      }
    } else if (play.note_tag === '2hunpen') {
      let calendar = helperHK.generateYearZodiac(new Date(), playId)
      let a = []
      let b = []
      if (String(selectedIds[0]).indexOf('1') === 0) {
        a = calendar[Number(ballinfo.balls[selectedIds[0]].cmd)]
      } else {
        a = ballinfo.balls[selectedIds[0]].subballs
      }

      if (String(selectedIds[1]).indexOf('1') === 0) {
        b = calendar[Number(ballinfo.balls[selectedIds[1]].cmd)]
      } else {
        b = ballinfo.balls[selectedIds[1]].subballs
      }

      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          if (a[i] !== b[j]) {
            let arr = []
            let str1 = a[i] < 10 ? ('0' + a[i]) : String(a[i])
            let str2 = b[j] < 10 ? ('0' + b[j]) : String(b[j])
            arr.push(str1)
            arr.push(str2)
            pairballs.push(arr)
          }
        }
      }
    }
    return pairballs
  }

  // 香港彩左标题
  static getFisrtPlayName(playid) {
    let result = []
    let first = playid.toString().substring(0, playid.toString().length - 3)
    // 连肖
    if ((playid >= Number(`${first}011`) && playid <= Number(`${first}029`)) || 
      (playid >= Number(`${first}083`) && playid <= Number(`${first}084`))) {
      result = ['四全中','三中二', '三全中', '二全中', '二中特', '特串']
    }
    // 合肖
    else if (playid >= Number(`${first}032`) && playid <= Number(`${first}053`)) {
      result = ['一肖', '二肖', '三肖', '四肖', '五肖', '六肖', '七肖', '八肖', '九肖', '十肖', '十一肖']
    }
    // 连肖
    else if (playid >= Number(`${first}062`) && playid <= Number(`${first}069`)) {
      result = ['连肖二肖', '连肖三肖', '连肖四肖', '连肖五肖']
    }
    // 连尾
    else if (playid >= Number(`${first}070`) && playid <= Number(`${first}081`)) {
      result = ['二尾', '三尾', '四尾', '二尾不中', '三尾不中', '四尾不中']
    }
    // 正特
    else if (playid >= Number(`${first}004`) && playid <= Number(`${first}009`)) {
      result = ['正码特一', '正码特二', '正码特三', '正码特四', '正码特五', '正码特六']
    }
    // 自选不中
    else if (playid >= Number(`${first}054`) && playid <= Number(`${first}061`)) {
      result = ['五不中', '六不中', '七不中', '八不中', '九不中', '十不中', '十一不中', '十二不中']
    }
    return result
  }

  // 香港彩右标题
  static getSecondPlayName(playid) {
    let first = playid.toString().substring(0, playid.toString().length - 3)
    let result = {}
    // 连码
    if ((playid >= Number(`${first}011`) && playid <= Number(`${first}029`)) || 
      (playid >= Number(`${first}083`) && playid <= Number(`${first}084`))) {
      result = {
        四全中: ['单选/复式', '胆拖'],
        三中二: ['单选/复式', '胆拖'],
        三全中: ['单选/复式', '胆拖'],
        二全中: ['单选/复式', '胆拖', '生肖对碰', '尾数对碰', '混合对碰'],
        二中特: ['单选/复式', '胆拖', '生肖对碰', '尾数对碰', '混合对碰'],
        特串: ['单选/复式', '胆拖', '生肖对碰', '尾数对碰', '混合对碰']
      }
      // 合肖
    } else if (playid >= Number(`${first}032`) && playid <= Number(`${first}053`)) {
      result = {
        一肖: ['中', '不中'],
        二肖: ['中', '不中'],
        三肖: ['中', '不中'],
        四肖: ['中', '不中'],
        五肖: ['中', '不中'],
        六肖: ['中', '不中'],
        七肖: ['中', '不中'],
        八肖: ['中', '不中'],
        九肖: ['中', '不中'],
        十肖: ['中', '不中'],
        十一肖: ['中', '不中']
      }
    }
    // 连肖
    else if (playid >= Number(`${first}062`) && playid <= Number(`${first}069`)) {
      result = {
        连肖二肖: ['单选/复式', '胆拖'],
        连肖三肖: ['单选/复式', '胆拖'],
        连肖四肖: ['单选/复式', '胆拖'],
        连肖五肖: ['单选/复式', '胆拖']
      }
    }
    // 连尾
    else if (playid >= Number(`${first}070`) && playid <= Number(`${first}081`)) {
      result = {
        二尾: ['单选/复式', '胆拖'],
        三尾: ['单选/复式', '胆拖'],
        四尾: ['单选/复式', '胆拖'],
        二尾不中: ['单选/复式', '胆拖'],
        三尾不中: ['单选/复式', '胆拖'],
        四尾不中: ['单选/复式', '胆拖']
      }
    }
    return result
  }

  //     // 香港彩 标题-玩法 对照表
  static getLLPlayDict(playid) {
    let result = {}
    let first = playid.toString().substring(0, playid.toString().length - 3)
    // 连码
    if ((playid >= Number(`${first}011`) && playid <= Number(`${first}029`)) || (playid >= Number(`${first}083`) && playid <= Number(`${first}084`))) {
      result = {
        四全中: [Number(`${first}083`), Number(`${first}084`)],
        三中二: [Number(`${first}011`), Number(`${first}012`)],
        三全中: [Number(`${first}013`), Number(`${first}014`)],
        二全中: [Number(`${first}015`), Number(`${first}016`), Number(`${first}017`), Number(`${first}018`), Number(`${first}019`)],
        二中特: [Number(`${first}020`), Number(`${first}021`), Number(`${first}022`), Number(`${first}023`), Number(`${first}024`)],
        特串: [Number(`${first}025`), Number(`${first}026`), Number(`${first}027`), Number(`${first}028`), Number(`${first}029`)]
      }
    }
    // 合肖
    else if (playid >= Number(`${first}032`) && playid <= Number(`${first}053`)) {
      result = {
        一肖: [Number(`${first}032`), Number(`${first}033`)],
        二肖: [Number(`${first}034`), Number(`${first}035`)],
        三肖: [Number(`${first}036`), Number(`${first}037`)],
        四肖: [Number(`${first}038`), Number(`${first}039`)],
        五肖: [Number(`${first}040`), Number(`${first}041`)],
        六肖: [Number(`${first}042`), Number(`${first}043`)],
        七肖: [Number(`${first}044`), Number(`${first}045`)],
        八肖: [Number(`${first}046`), Number(`${first}047`)],
        九肖: [Number(`${first}048`), Number(`${first}049`)],
        十肖: [Number(`${first}050`), Number(`${first}051`)],
        十一肖: [Number(`${first}052`), Number(`${first}053`)]
      }
    }
    // 连肖
    else if (playid >= Number(`${first}062`) && playid <= Number(`${first}069`)) {
      result = {
        连肖二肖: [Number(`${first}062`), Number(`${first}063`)],
        连肖三肖: [Number(`${first}064`), Number(`${first}065`)],
        连肖四肖: [Number(`${first}066`), Number(`${first}067`)],
        连肖五肖: [Number(`${first}068`), Number(`${first}069`)]
      }
    } else if (playid >= Number(`${first}070`) && playid <= Number(`${first}081`)) {
      result = {
        二尾: [Number(`${first}070`), Number(`${first}071`)],
        三尾: [Number(`${first}072`), Number(`${first}073`)],
        四尾: [Number(`${first}074`), Number(`${first}075`)],
        二尾不中: [Number(`${first}076`), Number(`${first}077`)],
        三尾不中: [Number(`${first}078`), Number(`${first}079`)],
        四尾不中: [Number(`${first}080`), Number(`${first}081`)]
      }
    }
    // 正特
    else if (playid >= Number(`${first}004`) && playid <= Number(`${first}009`)) {
      result = {
        正码特一: Number(`${first}004`),
        正码特二: Number(`${first}005`),
        正码特三: Number(`${first}006`),
        正码特四: Number(`${first}007`),
        正码特五: Number(`${first}008`),
        正码特六: Number(`${first}009`)
      }
    }
    // 自选不中
    else if (playid >= Number(`${first}054`) && playid <= Number(`${first}061`)) {
      result = {
        五不中: Number(`${first}054`),
        六不中: Number(`${first}055`),
        七不中: Number(`${first}056`),
        八不中: Number(`${first}057`),
        九不中: Number(`${first}058`),
        十不中: Number(`${first}059`),
        十一不中: Number(`${first}060`),
        十二不中: Number(`${first}061`)
      }
    }
    return result
  }
}