import dayjs from 'dayjs';
import network from '../../network';
import { useHot } from '/@/kernel-mn/stores/useHot';
import { useSportsStore } from '/@/kernel-mn/stores/sports';
import { get, setJSON } from '/@/kernel-mn/utils/storage';
import * as utils from '/@/kernel-mn/utils';
import {
  blackListFilter,
  dayEndTimeNew,
  dayStartTimeNew,
  displayCardcode,
  fixFloat,
  utilsTotal,
  isApp,
  getDeviceType
} from '/@/kernel-mn/utils';
import { GAME_MENU, GAMES } from '/@/kernel-mn/config/sports/index';
import config from '/@/kernel-mn/config';
import ElotType from '/@/kernel-mn/config/ElotType';
import { useGameStore } from '.';
import betHistoryConfig from '../otherGames/betHistoryConfig';
import historyUtil from '../otherGames/historyUtils';
import historyConfig from '../otherGames/gameHistory';
import helpers from '/@/kernel-mn/stores/otherGames/helpersOthersGames';
import helperKGQiPai from '/@/kernel-mn/helpers/helperKGQP';
import {
  ballsWithArea,
  createPlan,
  getBasketItemCMD,
  isFrost,
  isPairBall,
  parseWincode,
  sliptPlanWithSubPlay,
  startTime,
  stopTime
} from '/@/kernel-mn/helpers/helperPlay';
import helperHK from '/@/kernel-mn/helpers/helperHK';
import { mb_t } from '/@/plugins/global';
import { useMsgStore } from '/@/kernel-mn/stores/message';
import { useTenantStore } from '/@/kernel-mn/stores/tenant';
import RNEvent from '/@/kernel-mn/utils/RNEvent';
import { useHaxiStore } from "/@/kernel-mn/stores/haxi";

interface EventPayload {
  Market: number;
  SportId: number;
}

const getLanguageCode = locale => {
  const languageCodeObj = {
    cn: 'chs',
    en: 'eng',
    th: 'th',
    vi: 'vn',
    id: 'id',
    es: 'es'
  };
  return languageCodeObj[locale] || 'eng';
};

// IM体育单独处理
const formatIMSport = data => {
  const { hotData } = useHot();
  const { tenantInfo } = hotData;
  const proxy = Number(tenantInfo.value.proxy);
  const item = { ...data };
  let url = '';
  const tmp = tenantInfo.value.logo_manage.filter(items => items.message_id === 28 && items.pic_addr);
  if (tmp && tmp.length) {
    url = tmp[0].pic_addr;
  }
  if (+item.play_id === 931) {
    item.img_url = url;
    if (proxy === 0) {
      item.play_cname = tenantInfo.value.tenant.tenant_name;
    } else if (proxy === 1) {
      item.play_cname = tenantInfo.value.tenant.tenant_des;
    } else if (proxy === 2) {
      item.play_cname = tenantInfo.value.tenant.xmpp;
    }
  }
  return item;
};

export default {
  // 登录第三方游戏
  async login3Game() {
    const RNEventInstance = RNEvent.getInstance();
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      play_id: 931,
      cust_id: userInfo.value.cust_id,
      type: 'IM'
    };
    try {
      const res = await network.request({
        cmdId: 314501,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false,
        inHeaders: { Tid: tenantInfo.value.tid }
      });
      if (res && res.type === 'success') {
        this.game3LoginInfo = res.data;
        setJSON('game3LoginInfo', res.data);
        if (isApp()) {
          RNEventInstance.writeRecord('game3LoginInfo', res.data);
        }
        // 获取用户配置数据
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取 已结算 的注单
  async getBetList(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      langCode: getLanguageCode(get('language')),
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 3119658,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.ordersList = res.data.wagerList;
        return Promise.resolve(res.data.wagerList);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取 未结算 的注单
  async getStatementList() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      langCode: getLanguageCode(get('language')).toUpperCase()
    };
    try {
      const res: any = await network.request({
        cmdId: 3119657,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        this.ordersList = res.data.wagerList;
        return Promise.resolve(res.data.wagerList);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 赛事公告
  async getAnnouncement() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      langCode: getLanguageCode(get('language'))
    };
    try {
      const res: any = await network.request({
        cmdId: 3119669,
        data: postdata,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success' && res.data && res.data.Announcement) {
        const arr = [];
        res.data.Announcement.map(item => {
          const detail = item.AnnouncementDetail.filter(sub => {
            return sub.LanguageCode === getLanguageCode(get('language')).toUpperCase();
          });
          arr.push({
            PostingDate: item.PostingDate,
            AnnouncementDetail: detail[0]
          });
        });
        return Promise.resolve(arr);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 请求赛果
  async getCompletedresults(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    //发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      langCode: getLanguageCode(get('language')),
      ...payload
    };
    try {
      const res = await network.request({
        cmdId: 3119670,
        data: postdata,
        headerType: 2,
        paramType: 0
      });
      if (res && res.type === 'success' && res.data) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   *  获取热门游戏，热门电子，捕鱼
   */
  async reqNewHotGames() {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    // 发起请求
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 321541,
        data
      });
      if (res && res.type === 'success') {
        let newDada: any = {};
        if (res.data) {
          let arr = res.data[1] || [];
          arr = arr.sort((prev, next) => {
            return next.sort_v - prev.sort_v;
          });
          this.newHotGames = arr;
          for (let key in res.data) {
            if (Number(key) === 1) {
              newDada.hotGames = res.data[key];
            } else if (Number(key) === 2) {
              newDada.hotElectronic = res.data[key];
            } else if (Number(key) === 3) {
              newDada.hotFishing = res.data[key];
            } else if (Number(key) === 4) {
              newDada.redEnvelope = res.data[key];
            } else if (Number(key) === 5) {
              newDada.hotBoard = res.data[key];
            }
          }
        }
        if (newDada.hotGames || newDada.hotElectronic || newDada.hotFishing || newDada.redEnvelope || newDada.hotBoard) {
          this.setSmallClassGame({ data: newDada });
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获取所有玩法状态
   */
  async reqAllGameState() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 321534, // 无需登录
        data: [tenantInfo.value.tid],
        paramType: 2
      });
      if (res && res.type === 'success') {
        const gameStore = useGameStore();
        gameStore.setGameAllStates(res.data);
        // 获取用户配置数据
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 租户的彩种配置
  async reqConfigJson() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 320151,
        data: postdata,
        headerType: 2,
        paramType: 0,
        inHeaders: { Tid: tenantInfo.value.tid }
      });
      if (res && res.type === 'success') {
        const gameStore = useGameStore();
        gameStore.getAllLottery();
        const elots = config.deepCopy(res.data);
        gameStore.setTenantPlays(elots);
        // 获取用户配置数据
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * @name 获取第三方游戏记录
   * @param payload.play_id 第三方游戏ID
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqOthersGameHistory(payload: any = {}) {
    const { hotData, activeConfiguration } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let isShow = activeConfiguration.value['30']; // 是否脱敏
    let data: any = {};
    if (payload.is_clear) {
      this.othersGameHistory = {};
    }
    data.play_id = payload.play_id;
    data.tid = tenantInfo.value.tid;
    data.start_time = payload.start_time;
    data.end_time = payload.end_time;
    data.page_start = payload.page_start;
    data.num_per_page = payload.num_per_page;
    payload.cust_name && (data.cust_name = payload.cust_name);
    data.direct_type = payload.direct_type || 0;
    data.cust_id = userInfo.value.cust_id;
    if (payload.type === 'team') {
      delete data.cust_id;
      data.partner_name = userInfo.value.cust_name;
    }
    if (payload.info1) {
      data.info1 = payload.info1;
    }
    if (payload.info2) {
      data.info2 = payload.info2;
    }
    if (payload.info3) {
      data.info3 = payload.info3;
    }
    data.play_id = payload.playId !== 408 ? payload.play_id : 403;
    let gameUtil = historyConfig[payload.play_id];
    let cmdId = 390009;
    if (
      Number(data.play_id) === 421 ||
      Number(data.play_id) === 422 ||
      Number(data.play_id) === 422 ||
      Number(data.play_id) === 423 ||
      Number(data.play_id) === 468 ||
      Number(data.play_id) === 504 ||
      Number(data.play_id) === 490 ||
      Number(data.play_id) === 506 ||
      Number(data.play_id) === 507 ||
      Number(data.play_id) === 508 ||
      Number(data.play_id) === 571 ||
      Number(data.play_id) === 572 ||
      Number(data.play_id) === 509 ||
      Number(data.play_id) === 706 ||
      Number(data.play_id) === 707 ||
      Number(data.play_id) === 708 ||
      Number(data.play_id) === 709 ||
      Number(data.play_id) === 710 ||
      Number(data.play_id) === 711 ||
      Number(data.play_id) === 745 ||
      Number(data.play_id) === 750 ||
      Number(data.play_id) === 924 ||
      Number(data.play_id) === 925 ||
      Number(data.play_id) === 926 ||
      Number(data.play_id) === 927 ||
      Number(data.play_id) === 938 ||
      Number(data.play_id) === 939 ||
      Number(data.play_id) === 928 ||
      Number(data.play_id) === 929 ||
      Number(data.play_id) === 930 ||
      Number(data.play_id) === 746 ||
      Number(data.play_id) === 747 ||
      Number(data.play_id) === 931 ||
      Number(data.play_id) === 960 ||
      Number(data.play_id) === 961 ||
      Number(data.play_id) === 980 ||
      Number(data.play_id) === 981 ||
      Number(data.play_id) === 1031 ||
      Number(data.play_id) === 1308 ||
      Number(data.play_id) === 1305 ||
      Number(data.play_id) === 1306 ||
      Number(data.play_id) === 1309 ||
      Number(data.play_id) === 1312 ||
      Number(data.play_id) === 983 ||
      Number(data.play_id) === 1311 ||
      Number(data.play_id) === 1313 ||
      Number(data.play_id) === 1314 ||
      Number(data.play_id) === 1315 ||
      Number(data.play_id) === 1316 ||
      Number(data.play_id) === 1317 ||
      Number(data.play_id) === 1318 ||
      Number(data.play_id) > 1318
    ) {
      cmdId = 392001;
    }
    try {
      const res: any = await network.request({
        cmdId: cmdId,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        const _data = res.data;
        let datalist = {
          count: _data.count,
          t: _data.t,
          list: []
        };
        if (_data.data && _data.count > 0) {
          _data.data.map(item => {
            let obj: any = {};
            if (isShow && item.thirdUid) item.thirdUid = displayCardcode(item.thirdUid, 1, 4);
            if (isShow && item.cust_name) item.cust_name = displayCardcode(item.cust_name, 1, 4);
            if (isShow && item.cust_code) item.cust_code = displayCardcode(item.cust_code, 1, 4);
            obj.titleArr = historyUtil.getTitle(item, gameUtil.showTitle);
            if (data.play_id === 570 && !obj.titleArr.gameName) {
              obj.titleArr.gameName = helpers.getGameName('getSBOTYName', item.gameType);
              obj.titleArr.gameType = item.gameType;
            }
            if (data.play_id === 572 && !obj.titleArr.gameName) {
              obj.titleArr.gameName = helpers.getGameName('EBETSXGames', item.gameType);
              obj.titleArr.gameType = item.gameType;
              obj.titleArr.money = Number(fixFloat(utilsTotal.moneyToFloat(item.profit)).toFixed(2));
            }
            if (data.play_id === 745 && !obj.titleArr.gameName) {
              obj.titleArr.gameName = helperKGQiPai.getKGQPGameName(item.gameId);
              obj.titleArr.gameType = item.gameId;
              obj.titleArr.money = Number(fixFloat(utilsTotal.moneyToFloat(item.winAmount)).toFixed(2));
            }
            if (data.play_id === 750 && !obj.titleArr.gameName) {
              obj.titleArr.gameName = helpers.getSEXYSportsName(item.sportName);
              obj.titleArr.money = Number(fixFloat(utilsTotal.moneyToFloat(item.payoutAmount)).toFixed(2));
            }
            obj.dataList = historyUtil.getDataList(item, gameUtil.pageShow);
            datalist.list.push(obj);
          });
        }
        return Promise.resolve(datalist);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获取方案列表
   * @param @requires payload.page_start 起始页
   * @param @requires payload.num_per_page 每页条数
   * @param payload.start_time 起始时间
   * @param payload.end_time 结束时间
   * @param payload.is_clear 是否清空本地数据(不清空则追加)
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqPlans(payload: any = {}) {
    const { hotData, activeConfiguration } = useHot();
    let isShow = activeConfiguration.value['30']; // 是否脱敏
    const { tenantInfo, userInfo } = hotData;
    if (payload.is_clear) {
      this.playPlanList = Object.assign({}, {});
    }
    const relateWeek = new Date(Date.now() - 1000 * 3600 * 24 * 7);
    const newPayload = JSON.parse(JSON.stringify(payload));
    if (newPayload.dataType !== 'reqPlansForm') {
      newPayload.cust_id = payload.cust_id || userInfo.value.cust_id; // 此处多个地方需要
      newPayload.plan_type = payload.plan_type || 1;
    } else {
      // 团队彩票
      newPayload.plan_type = payload.plan_type || '1,3';
    }
    newPayload.platform_order_id = newPayload.plan_id || '';
    delete newPayload['plan_id'];
    const ymd = new Date(Date.now());
    const data = {
      ...newPayload,
      tid: tenantInfo.value.tid,
      play_name: payload.play_name || '',
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
      start_time: payload.start_time ? dayStartTimeNew(payload.start_time) : dayStartTimeNew(relateWeek) + '',
      end_time: payload.end_time ? dayEndTimeNew(payload.end_time) : dayEndTimeNew(ymd) + ''
    };
    delete data.is_clear;
    try {
      const res: any = await network.request({
        cmdId: 320631,
        data: data
      });
      if (res && res.type === 'success') {
        let sumRewardAmount = 0;
        let sumRebateAmount = 0;
        let sumReturnAmount = 0;
        let sumMoneyBuys = 0;
        if (res.data && res.data.data && res.data.data.length !== 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let tempData: any = {};
            tempData = res.data.data[i];
            res.data.data[i].profit = utilsTotal.moneyToFloat(tempData.reward_pretax + tempData.rebate_amount + tempData.return_amount - tempData.amount);
            // }
            if (isShow && newPayload.dataType === 'reqPlansForm') {
              res.data.data[i].cust_code = res.data.data[i].cust_code.split('_')[res.data.data[i].cust_code.split('_').length - 1];
              res.data.data[i].cust_code = utils.displayCardcode(res.data.data[i].cust_code, 1, 4);
              res.data.data[i].cust_name = utils.displayCardcode(res.data.data[i].cust_name, 1, 4);
            }
            res.data.data[i].plan_time = utilsTotal.dateFormat(tempData.plan_time);
            sumMoneyBuys += tempData.amount;
            res.data.data[i].amount = utilsTotal.moneyToFloat(tempData.amount);
            sumRewardAmount += tempData.reward_pretax;
            sumRebateAmount += tempData.rebate_amount;
            sumReturnAmount += tempData.return_amount;
            res.data.data[i].reward_pretax = utilsTotal.moneyToFloat(tempData.reward_pretax);
            res.data.data[i].return_amount = utilsTotal.moneyToFloat(tempData.return_amount);
            res.data.data[i].moneyBuys = utils.moneyString(tempData.amount);
            switch (tempData.state) {
              case 1:
                res.data.data[i].stateStr = mb_t('未满员');
                break;
              case 2:
                res.data.data[i].stateStr = mb_t('已满员');
                break;
              case 3:
                res.data.data[i].stateStr = mb_t('送票中');
                break;
              case 4:
                res.data.data[i].stateStr = mb_t('已出票');
                break;
              case 5:
                res.data.data[i].stateStr = mb_t('已结算');
                break;
              case 8:
                res.data.data[i].stateStr = mb_t('已撤单');
                break;
              case 9:
                res.data.data[i].stateStr = mb_t('方案失败');
                break;
              default:
            }
            if (tempData.plan_type !== 3) {
              switch (tempData.win_flag) {
                case 2:
                  res.data.data[i].stateStr = mb_t('未中奖');
                  break;
                case 3:
                  res.data.data[i].stateStr = mb_t('已中奖');
                  break;
                case 4:
                  res.data.data[i].stateStr = mb_t('和局');
                  break;
              }
            } else if (tempData.state === 4) {
              res.data.data[i].stateStr = tempData.disp_flag === 1 ? mb_t('追号中') : tempData.disp_flag === 4 ? mb_t('已结束') : mb_t('待付款');
            }
            res.data.data[i].revoke_flag = tempData.revoke_flag === 1 && tempData.state !== 8 ? 1 : 0;
            switch (res.data.data[i].dup_cat) {
              case 0:
                res.data.data[i].dup_catStr = mb_t('默认');
                break;
              case 1:
                res.data.data[i].dup_catStr = mb_t('利润率追号');
                break;
              case 2:
                res.data.data[i].dup_catStr = mb_t('同倍追号');
                break;
              case 3:
                res.data.data[i].dup_catStr = mb_t('翻倍追号');
                break;
              default:
            }
            // 追号信息
            if (tempData.plan_type === 3 || tempData.plan_type === '3') {
              res.data.data[i].reward = tempData.reward_aftertax ? utilsTotal.moneyToFloat(tempData.reward_aftertax) : 0;
              res.data.data[i].win_endStr = tempData.win_end === 1 ? mb_t('是') : mb_t('否');
              res.data.data[i].rebateAmount = utils.moneyString(utilsTotal.moneyToFloat(tempData.rebate_amount));
              res.data.data[i].rewardAmount = utils.moneyString(utils.fixFloat(tempData.reward_pretax));
              res.data.data[i].returnAmount = utils.moneyString(utilsTotal.moneyToFloat(tempData.return_amount));
            }
          }
        }
        if (res.data.t) {
          res.data.t.profit = utilsTotal.moneyToFloat(sumRewardAmount - sumMoneyBuys + sumRebateAmount + sumReturnAmount);
          res.data.t.sumRewardAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumRewardAmount));
          res.data.t.sumRebateAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumRebateAmount));
          res.data.t.sumReturnAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumReturnAmount));
          res.data.t.sumMoneyBuys = utils.fixFloat(utilsTotal.moneyToFloat(sumMoneyBuys));
          // 原本就有的进行转换
          res.data.t.play_amount = utils.fixFloat(utilsTotal.moneyToFloat(res.data.t.play_amount));
          res.data.t.award_amount = utils.fixFloat(utilsTotal.moneyToFloat(res.data.t.award_amount));
          res.data.t.rebate_amount = utils.fixFloat(utilsTotal.moneyToFloat(res.data.t.rebate_amount));
          res.data.t.return_amount = utils.fixFloat(utilsTotal.moneyToFloat(res.data.t.return_amount));
          res.data.t.profit_amount = utils.fixFloat(utilsTotal.moneyToFloat(res.data.t.profit_amount));
        } else {
          res.data.t = {};
          res.data.t.play_amount = false;
          res.data.t.profit = utilsTotal.moneyToFloat(sumRewardAmount - sumMoneyBuys + sumRebateAmount + sumReturnAmount);
          res.data.t.sumRewardAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumRewardAmount));
          res.data.t.sumRebateAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumRebateAmount));
          res.data.t.sumReturnAmount = utils.fixFloat(utilsTotal.moneyToFloat(sumReturnAmount));
          res.data.t.sumMoneyBuys = utils.fixFloat(utilsTotal.moneyToFloat(sumMoneyBuys));
        }
        if (payload.is_clear !== 2 && payload.dataType !== 'reqPlansForm') {
          this.playPlanList = Object.assign({}, res.data);
        }
        return Promise.resolve(res.data);
      } else {
        this.playPlanList = Object.assign({}, {});
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  setPlayPlanList2(data) {
    this.playPlanList = Object.assign({}, data);
  },
  /** 获取方案列表(App)
   * @param @requires payload.page_start 起始页
   * @param @requires payload.num_per_page 每页条数
   * @param payload.start_time 起始时间
   * @param payload.end_time 结束时间
   * @param payload.is_clear 是否清空本地数据(不清空则追加)
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqPlansApp(payload: any = {}) {
    const { hotData, activeConfiguration } = useHot();
    let isShow = activeConfiguration.value['30']; // 是否脱敏
    const { tenantInfo, userInfo } = hotData;
    let data: any = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      game_type: payload.game_type,
      page_start: payload.page_start ? payload.page_start : 1,
      num_per_page: payload.num_per_page ? payload.num_per_page : 20,
      plan_type: payload.plan_type,
      play_id: payload.play_id
    };
    payload.play_name && (data.play_name = payload.play_name);
    payload.type === 'team' && (data.cust_name = userInfo.value.cust_name);
    payload.win_flag && (data.win_flag = payload.win_flag);
    payload.state && (data.state = payload.state);
    if (payload.type === 'team') {
      delete data['cust_id'];
      payload.playerName && (data.cust_code = payload.playerName);
      data.start_time = payload.start_time;
      data.direct_type = 0;
      data.partner_name = userInfo.value.cust_name;
      data.end_time = payload.end_time || dayEndTimeNew(Date.now()) + '';
    } else {
      // const relateWeek = new Date(Date.now() - 1000 * 3600 * 24 * 14);
      // data.start_time = dayStartTimeNew(relateWeek);
      // data.end_time = dayEndTimeNew(Date.now());
    }
    if (payload.startTime) {
      data.start_time = payload.startTime;
    }
    if (payload.endTime) {
      data.end_time = payload.endTime;
    }
    if (payload.api_type) {
      data.api_type = payload.api_type;
    }

    if (payload.room_level) {
      data.room_level = payload.room_level
    }
    try {
      const res: any = await network.request({
        cmdId: payload.type === 'team' ? 320631 : 320634,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      console.log('xxxxpayload.type ===,',payload.type === 'team' ? 320631 : 320634,data)
      if (res && res.type === 'success') {
        let playPlanList = [];
        if (res.data && res.data.count > 0) {
          res.data.data.map(item => {
            let createDate = new Date(item.create_time);
            let planMonth = createDate.getMonth() + 1;
            let planDay = createDate.getDate();
            let planHour = utils.strPadLeft(createDate.getHours(), 2);
            let planMin = utils.strPadLeft(createDate.getMinutes(), 2);
            if (isShow && payload.type === 'team') {
              item.cust_code = item.cust_code.split('_')[item.cust_code.split('_').length - 1];
              item.cust_code = utils.displayCardcode(item.cust_code, 1, 4);
            }
            item.plan_date_js = planMonth + '/' + planDay;
            item.plan_time_js = planHour + ':' + planMin;
            playPlanList.push(item);
          });
        }
        this.playPlanList = {
          page_start: payload.page_start,
          data: playPlanList,
          count: res.data.count
        };
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 获得方案详情
   * @param @requires payload.plan_id 方案ID
   * @param @requires payload.part_mon
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqPlanDetail(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = [tenantInfo.value.tid, payload.part_mon, payload.plan_id];
    try {
      const res = await network.request({
        cmdId: 320630,
        paramType: 2,
        data
      });
      if (res && res.type === 'success') {
        // 把用户在投注列表中选择的查看详情的那一期方案放入state中
        // const gameStore = useGameStore()
        // gameStore.setCurrentPlayPlan(res.data.playPlan)
        this.setPlanDetail(res.data);
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /** 取消订单
   * @param @requires payload.plan_id
   * @param @requires payload.part_mon
   * @param @requires payload.ori_state
   * @param payload.key 本次请求的key，默认为default
   * @param payload.options 更多参数 JSON
   * @param payload.callback 回调 function (res, err) {}
   */
  async reqCancelPlan(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      oper_code: userInfo.value.cust_code,
      state: payload.state || 8,
      plan_id: payload.plan.plan_id,
      ori_state: payload.plan.state,
      part_mon: payload.plan.part_mon
    };
    try {
      const res: any = await network.request({
        cmdId: 320611,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success') {
        const sportsStore = useSportsStore();
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 把用户在投注列表中选择的查看详情的那一期方案放入state中
   * @param context
   * @param payload
   */
  setCurrentPlayPlan(payload) {
    setJSON('currentPlayPlan', payload.playPlan);
    this.currentPlayPlan = payload.playPlan;
  },
  // 设置收藏
  async setPlayCollection(
    payload: any = {
      data: {}
    }
  ) {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    const { play_id, type, _type, play_cat, play_collection } = payload.data;
    if (!payload.data.upper_id) {
      payload.data.upper_id = payload.data.upper_play_id;
    }
    const data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      play_id,
      type: _type || type,
      play_cat: typeof play_cat === 'number' ? play_cat : 1,
      status: play_collection,
      msg: JSON.stringify(payload.data),
      is_room: payload.data.is_room !== undefined ? payload.data.is_room : 1
    };
    try {
      const res = await network.request({
        cmdId: 320567,
        data
      });
      if (res && res.type === 'success') {
        payload.callback && payload.callback(res.data);
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 收藏列表
  async getPlayCollection() {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 320566,
        data: postdata
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取带租户玩法状态的玩法列表
  async reqPlayGames(payload: any = {}) {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    if (payload.isClear) {
      this.playGames = [];
    }
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id || 0
    } as any;
    if (payload && payload.isDz) {
      postdata.upper_id = '-1';
    }
    try {
      const res = await network.request({
        cmdId: 320146,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        this.playGames = res.data;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 热门游戏
  async reqHotGames() {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      size: 12,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 321538,
        data: postdata
      });
      if (res && res.type === 'success') {
        let response = blackListFilter(res.data, userInfo.value.blacklist_game, userInfo.value.cust_name);
        this.hotGames = response;
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取棋牌页面可玩的棋牌大玩法状态列表
  async reqThirdQiPaiGames(payload) {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    // 发起请求
    const data: any = {
      tid: tenantInfo.value.tid,
      play_ids: payload.gameList.toString()
    };
    const custId = userInfo.value.cust_id;
    if (custId) {
      data.cust_id = custId;
    }
    try {
      const res = await network.request({
        isApiV2: true,
        cmdId: 320147,
        data
      });
      if (res && res.type === 'success') {
        window.localStorage.setItem('QiPaiPlayGames', JSON.stringify(res.data));
        this.qiPaiPlayGames = res.data;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Market 指出“早盘”、“今日”或者“滚球”的盘口  早盘1 今日2 滚球3
  // SportId 玩法    1足球 2篮球 3网球 6田径......
  // 获取比赛delta数据
  async getDeltaEventInfoMBT(payload: any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const { userConfig } = useSportsStore();

    const data = {
      tid: tenantInfo.value.tid,
      Delta: dayjs().format('YYMMDDHHmmss000'), // yyMMddHHmmssfff
      page: 1,
      num: 100,
      pageType: 1, // 分页模式 1=切换模式;2 = 滚动加载模式
      sort_type: this.leagueSortType, // 1=联赛; 2=时间
      competitionIds: this.leaguSelected.join(','), //联赛过滤
      dateStr: payload.Market === 1 ? this.leagueDate : '', //时间过滤
      token: this.game3LoginInfo.Token || '',
      memberCode: this.game3LoginInfo.MemberCode || '',
      oddsType: userConfig.SelectedOddsType,
      orderBy: 1, // 收藏排序类型
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 3119654,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res?.data && res.type === 'success') {
        const _sportsEvents = res.data.list || [];
        const _eventsLists = {};
        const competitions = {}; // 联赛分类对象
        const competitionIds = []; // 联赛id
        const teamIds = []; // 球队id
        const totalMatch = res.data?.size || 0;
        // 循环获取所有的teamID和competitionId做翻译
        _sportsEvents.map(v => {
          if (!teamIds.includes(v.homeTeamId)) {
            teamIds.push(v.homeTeamId);
          }
          if (!teamIds.includes(v.awayTeamId)) {
            teamIds.push(v.awayTeamId);
          }
          if (!competitionIds.includes(v.competitionId)) {
            competitionIds.push(v.competitionId);
          }
        });
        // 联赛数据
        const commap = await this.getSportsLocalization({
          LocalizationType: 51,
          IdList: competitionIds.join(',')
        });
        // 球队数据
        const awsmap = await this.getSportsLocalization({
          LocalizationType: 53,
          IdList: teamIds.join(',')
        });

        _sportsEvents.map(v => {
          _eventsLists[v.eventId] = {
            ...v,
            // 翻译联赛和球队名称
            en_awayTeam: v.awayTeam,
            en_homeTeam: v.homeTeam,
            en_competitionName: v.competitionName,
            awayTeam: awsmap[v.awayTeamId] || v.awayTeam,
            homeTeam: awsmap[v.homeTeamId] || v.homeTeam,
            competitionName: commap[v.competitionId] || v.competitionName
          };
          // 更新串关数据
          this.updateCrossData(_eventsLists[v.eventId]);

          // 数据按联赛分类
          if (competitions[v.competitionId]) {
            competitions[v.competitionId].matchs.push(_eventsLists[v.eventId]);
          } else {
            competitions[v.competitionId] = {
              matchs: [_eventsLists[v.eventId]],
              name: commap[v.competitionId] || v.competitionName
            };
          }
        });
        // 更新热门和串关数据
        // 矫正菜单数据
        const _menus = this.sportMenus;
        _menus.map((v, index) => {
          if (v.key === payload.Market) {
            if (!this.sportMenus[index]['submenu']) {
              this.sportMenus[index]['submenu'] = {};
            }

            const _oldNums = this.sportMenus[index]['submenu'][payload.SportId] || 0;
            const diff = totalMatch - _oldNums;
            const totalGame = this.sportMenus[index]['totalGame'] + diff;
            this.sportMenus[index]['submenu'][payload.SportId] = totalMatch;
            this.sportMenus[index]['totalGame'] = totalGame;
            if (v.key === this.currentMarket.key) {
              // if (!this.currentMarket.submenu) {
              //   this.currentMarket.submenu = {}
              // }
              // if (!this.currentMarket.submenu[payload.SportId]) {
              //   this.currentMarket.submenu[payload.SportId] = []
              // }
              if (!this.currentMarket.submenu) {
                this.currentMarket.submenu = {};
              }
              this.currentMarket.submenu[payload.SportId] = totalMatch;
              this.currentMarket = {
                ...this.currentMarket,
                totalGame
              };
            }
          }
        });

        if (payload.Market !== this.currentMarket.key || payload.SportId != this.currentGame) {
          return false;
        }

        // 下标分页模式数据
        if (data.pageType === 1) {
          return Promise.resolve({
            total: totalMatch,
            page: data.page,
            list: competitions,
            listIds: competitionIds,
            finished: _sportsEvents.length < data.num
          });
        }
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async getHotEventInfo(payload: any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      type: 1, // 1=热门赛事,2=热门联赛
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 3119672,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res?.data && res.type === 'success') {
        const _sportsEvents = res.data.list || [];
        const competitionIds = []; // 联赛id
        const teamIds = []; // 球队id
        // 循环获取所有的teamID和competitionId做翻译
        _sportsEvents.map(v => {
          if (!teamIds.includes(v.homeTeamId)) {
            teamIds.push(v.homeTeamId);
          }
          if (!teamIds.includes(v.awayTeamId)) {
            teamIds.push(v.awayTeamId);
          }
          if (!competitionIds.includes(v.competitionId)) {
            competitionIds.push(v.competitionId);
          }
        });
        // 联赛数据
        const commap = await this.getSportsLocalization({
          LocalizationType: 51,
          IdList: competitionIds.join(',')
        });
        // 球队数据
        const awsmap = await this.getSportsLocalization({
          LocalizationType: 53,
          IdList: teamIds.join(',')
        });

        const _newSport = _sportsEvents.map(v => {
          return {
            ...v,
            // 翻译联赛和球队名称
            en_awayTeam: v.awayTeam,
            en_homeTeam: v.homeTeam,
            en_competitionName: v.competitionName,
            awayTeam: awsmap[v.awayTeamId] || v.awayTeam,
            homeTeam: awsmap[v.homeTeamId] || v.homeTeam,
            competitionName: commap[v.competitionId] || v.competitionName
          };
        });
        // 热门联赛
        if (data.type === 2) {
          return Promise.resolve(_newSport);
        } else {
          this.hotEvents = _newSport;
          return Promise.resolve(_newSport);
        }
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取所有联赛列表
  async getLeagueAll(payload: any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      tid: tenantInfo.value.tid,
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 3119673,
        data: data,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res?.data && res.type === 'success') {
        const listData = res.data;
        const competitionIds = Object.keys(listData) || [];
        // 联赛数据
        const commap = await this.getSportsLocalization({
          LocalizationType: 51,
          IdList: competitionIds.join(',')
        });
        const transData = [];
        competitionIds.map(v => {
          transData.push({
            ...listData[v],
            CompetitionName: commap[v] || listData[v].CompetitionName
          });
        });
        return Promise.resolve(transData);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取菜单游戏比赛个数
  async getMenuGameNums() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    let postdata = {
      tid: tenantInfo.value.tid,
      play_id: 931,
      cust_id: userInfo.value.cust_id,
      type: 'IM'
    };
    try {
      const res: any = await network.request({
        cmdId: 3119663,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        // 滚球=RBFECount
        // 今日=TodayFECount +RBFECount
        // 早盘=EarlyFECount
        const menuNums = [...GAME_MENU];
        res.data.map((v, i) => {
          if (GAMES.includes(v.SportId)) {
            menuNums.map((m, index) => {
              const _temp = {};
              if (!menuNums[index]['submenu']) {
                menuNums[index]['submenu'] = {};
              }

              if (i === 0) {
                menuNums[index]['totalGame'] = 0;
              }
              switch (m.key) {
                case 3: // 滚球
                  _temp[v.SportId] = v.RBFECount;
                  break;
                case 2: // 今日
                  _temp[v.SportId] = v.TodayFECount + v.RBFECount;
                  break;
                case 1: // 早盘
                  _temp[v.SportId] = v.EarlyFECount;
                  break;
                case 5: // 冠军
                  _temp[v.SportId] = v.ORCount;
                  break;
                default:
                  _temp[v.SportId] = 0;
              }
              menuNums[index]['totalGame'] += _temp[v.SportId];
              menuNums[index]['submenu'] = {
                ...menuNums[index]['submenu'],
                ..._temp
              };
            });
          }
        });
        this.sportMenus = menuNums;
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 添加收藏
  async collect(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      ...payload,
      tid: tenantInfo.value.tid,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      type: 'IM'
    };
    try {
      const res = await network.request({
        cmdId: 3119659,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        // 更新关注数据
        GAME_MENU.map((v, index) => {
          // 关注
          if (v.key === 4) {
            // 更新收藏列表
            this.sportMenus[index]['totalGame'] += 1;
            this.sportMenus[index]['submenu'][payload.SportId] += 1;
          }
        });
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 删除收藏
  async unCollect(payload: any = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      ...payload,
      tid: tenantInfo.value.tid,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      type: 'IM'
    };
    try {
      const res = await network.request({
        cmdId: 3119661,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        // 更新关注数据
        GAME_MENU.map((v, index) => {
          // 关注
          if (v.key === 4) {
            // 更新收藏列表
            const currentTotal = this.sportMenus[index]['totalGame'];
            const currentSubmenu = this.sportMenus[index]['submenu'][payload.SportId];
            this.sportMenus[index]['totalGame'] = currentTotal ? currentTotal - 1 : 0;
            this.sportMenus[index]['submenu'][payload.SportId] = currentSubmenu ? currentSubmenu - 1 : 0;
          }
        });
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 关注列表
  async getCollectionList(payload: any = {}) {
    const { hotData } = useHot();
    const { userConfig } = useSportsStore();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    const postdata: any = {
      tid: tenantInfo.value.tid,
      play_id: 931,
      cust_id: userInfo.value.cust_id,
      Token: this.game3LoginInfo.Token || '',
      MemberCode: this.game3LoginInfo.MemberCode || '',
      OddsType: userConfig.SelectedOddsType,
      OrderBy: 1, // 排序类型
      type: 'IM',
      langCode: getLanguageCode(get('language'))
    };

    if (payload.SportId) {
      postdata.Sports = payload.SportId;
    }

    if (!postdata.Token || !postdata.MemberCode) {
      return false;
    }
    try {
      const res: any = await network.request({
        cmdId: 3119660,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.data?.StatusCode === 100) {
        const _sports = res.data.Sports || [];
        if (_sports.length) {
          const _sportsEvents = _sports[0].Events;
          const _eventsLists = {};
          const competitions = {}; // 联赛分类对象
          const totalMatch = res.data?.size || _sportsEvents.length;
          const competitionIds = []; // 联赛id
          const teamIds = [];
          // 循环获取所有的teamID和competitionId做翻译
          _sportsEvents.map(v => {
            const comp = JSON.parse(v.competition);
            if (!teamIds.includes(v.homeTeamId)) {
              teamIds.push(v.homeTeamId);
            }
            if (!teamIds.includes(v.awayTeamId)) {
              teamIds.push(v.awayTeamId);
            }
            if (!competitionIds.includes(comp.CompetitionId)) {
              competitionIds.push(comp.CompetitionId);
            }
          });
          // 联赛数据
          const commap = await this.getSportsLocalization({
            LocalizationType: 51,
            IdList: competitionIds.join(','),
            force: 1
          });
          // 球队数据
          const awsmap = await this.getSportsLocalization({
            LocalizationType: 53,
            IdList: teamIds.join(','),
            force: 1
          });
          _sportsEvents.map(v => {
            const comp = JSON.parse(v.competition);
            _eventsLists[v.eventId] = {
              ...v,
              // 翻译联赛和球队名称
              en_awayTeam: v.awayTeam,
              en_homeTeam: v.homeTeam,
              en_competitionName: comp.CompetitionName,
              awayTeam: awsmap[v.awayTeamId] || v.awayTeam,
              homeTeam: awsmap[v.homeTeamId] || v.homeTeam,
              competitionId: comp.CompetitionId,
              competitionName: commap[comp.CompetitionId] || comp.CompetitionName
            };

            // 数据按联赛分类
            if (competitions[comp.CompetitionId]) {
              competitions[comp.CompetitionId].matchs.push(_eventsLists[v.eventId]);
            } else {
              competitions[comp.CompetitionId] = {
                matchs: [_eventsLists[v.eventId]],
                name: commap[comp.CompetitionId] || comp.CompetitionName
              };
            }
          });

          // 更新热门和串关数据
          // 矫正菜单数据
          const _menus = this.sportMenus;
          _menus.map((v, index) => {
            if (v.key === 4) {
              if (!this.sportMenus[index]['submenu']) {
                this.sportMenus[index]['submenu'] = {};
              }

              const _oldNums = this.sportMenus[index]['submenu'][payload.SportId] || 0;
              const diff = totalMatch - _oldNums;
              const totalGame = this.sportMenus[index]['totalGame'] + diff;
              this.sportMenus[index]['submenu'][payload.SportId] = totalMatch;
              this.sportMenus[index]['totalGame'] = totalGame;

              if (4 === this.currentMarket.key) {
                if (!this.currentMarket.submenu) {
                  this.currentMarket.submenu = {};
                }
                this.currentMarket.submenu[payload.SportId] = totalMatch;
                this.currentMarket = {
                  ...this.currentMarket,
                  totalGame
                };
              }
            }
          });
          return Promise.resolve({
            total: totalMatch,
            page: 1,
            list: competitions,
            listIds: competitionIds,
            finished: true
          });
        } else {
          return Promise.resolve({
            total: 0,
            page: 1,
            list: {},
            listIds: [],
            finished: true
          });
        }
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 索取 DELTA 优胜冠军赛事
  async getDeltaGuanjunEventInfo(payload: EventPayload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      ...payload,
      Delta: dayjs().format('YYMMDDHHmmss000') // yyMMddHHmmssfff
    };
    try {
      const res = await network.request({
        cmdId: 3119665,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        // todo
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 冠军列表
  async getGuanJunList(payload: any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const postdata = {
      ...payload,
      tid: tenantInfo.value.tid,
      play_id: 931,
      cust_id: userInfo.value.cust_id,
      langCode: getLanguageCode(get('language'))
    };
    try {
      const res: any = await network.request({
        cmdId: 3119664,
        data: postdata,
        headerType: 2,
        paramType: 0,
        isApiV2: false
      });
      if (res && res.type === 'success' && res.data) {
        const _sportsEvents = res.data?.Events;
        const _eventsLists = {},
          _sportsEventIds = [];
        const ids = [];
        // 循环获取所有的teamID和competitionId做翻译
        _sportsEvents.map(v => {
          // EventGroupTypeId=1 主要赛事
          if (v.eventStatusId == 1) {
            if (!ids.includes(v.eventId)) {
              ids.push(v.eventId);
            }
          }
        });
        // 冠军数据
        const gjmap = await this.getSportsLocalization({
          LocalizationType: 52,
          IdList: ids.join(',')
        });
        _sportsEvents.map(v => {
          // EventGroupTypeId=1 主要赛事
          if (v.eventStatusId == 1) {
            _eventsLists[v.eventId] = {
              ...v,
              competition: JSON.parse(v.competition),
              outrightEventName: gjmap[v.eventId] || v.outrightEventName
            };
            _sportsEventIds.push(v.eventId);
          }
        });

        // 矫正菜单数据
        const _menus = this.sportMenus;
        _menus.map((v, index) => {
          if (v.key === 5) {
            if (!this.sportMenus[index]['submenu']) {
              this.sportMenus[index]['submenu'] = {};
            }
            const _oldNums = this.sportMenus[index]['submenu'][payload.SportId] || 0;
            const diff = _sportsEventIds.length - _oldNums;
            const totalGame = this.sportMenus[index]['totalGame'] + diff;
            this.sportMenus[index]['submenu'][payload.SportId] = _sportsEventIds.length;
            this.sportMenus[index]['totalGame'] = totalGame;
            if (v.key === this.currentMarket.key) {
              this.currentMarket.submenu[payload.SportId] = _sportsEventIds.length;
              this.currentMarket = {
                ...this.currentMarket,
                totalGame
              };
            }
          }
        });
        // 所有赛事缓存数据
        if (!payload.self) {
          this.guanjunDetailLists = _eventsLists;
        } else {
          return Promise.resolve(_eventsLists);
        }
        // todo
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 下注
  async bet(payload = {}) {
    // 发起请求
    const postdata = {
      // tid: tenantInfo.value.tid,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      // type: 'IM',
      WagerType: 1, // 指投注性质 1 = Single 单注;  2 = Combo 连串过关
      CustomerIP: '192.168.10.11', //客户 IP 地址
      ServerIP: '192.168.14.128', //伺服器 IP 地址
      CustomerMACAddress: null, //客户 MAC 地址
      UserAgent: 'Mozilla', //用户代理字符
      AppDeviceName: null, //设备名. 只强制客户端渠道
      AppModel: null, //设备型号. 只强制客户端渠道
      AppOSVersion: null, //OS 版设备. 只强制客户端渠道
      AppPlatform: null, //设备平台. 只强制客户端渠道
      AppVersion: null, //客户端版本. 只强制客户端渠道
      IsComboAcceptAnyOdds: false, //指出会员投注时出现赔率变更并是否接受任何赔率。默认是只接受跟高的赔率
      WagerSelectionInfos: [], //投注选项详情清单
      ComboSelections: [], //连串过关选项清单
      TimeStamp: new Date().valueOf(), //新生成的时间戳将持续 5 分钟
      LanguageCode: 1, //使用会员语言投注
      IsCombo: false, //指出计数是连串过关赛事或者非连串过关赛事。
      ...payload
    };
    try {
      const res: any = await network.request({
        cmdId: 3119656,
        data: postdata,
        headerType: 2,
        paramType: 1,
        isApiV2: false
      });
      if (res.data?.statusCode === 100) {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 请求投注面板信息
  async getBetInfo(payload) {
    const data = {
      ...payload,
      Token: this.game3LoginInfo.Token,
      MemberCode: this.game3LoginInfo.MemberCode,
      type: 'IM'
    };
    try {
      const res = await network.request({
        cmdId: 3119668,
        data: data,
        headerType: 2,
        paramType: 1
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取投注记录列表信息
   */
  async reqBetHistoryList(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      num_per_page: payload.num_per_page,
      page_start: payload.page_start,
      start_time: payload.start_time,
      end_time: payload.end_time,
      type_id: payload.type_id
    };
    payload.time_type && (data.time_type = payload.time_type);
    payload.platform && (data.platform = payload.platform);
    try {
      const res = await network.request({
        cmdId: 314502,
        data: data
      });
      if (res && res.type === 'success') {
        // 字段统一化处理
        const resData: any = res.data || {};
        if (resData && resData.count > 0) {
          resData.data.map(item => {
            const gameInfo = config.getPlayInfo(item.play_type_id);
            let playTypeId = gameInfo ? gameInfo.play_cname : item.play_type_id;
            let { gameTypeId, platformOrderId } = betHistoryConfig.formatValue(item.play_type_id, item, playTypeId);
            let platformOrderId2 = platformOrderId ? platformOrderId : item.platform_order_id;
            item.playTypeId = playTypeId;
            item.gameTypeId = gameTypeId || item.game_type_id;
            item.platform_order_id = platformOrderId2;
          });
        }
        return Promise.resolve(resData);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 按天统计投注历史记录
   */
  async reqBetHistoryByDay(payload: any = {}) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      num_per_page: payload.num_per_page || 10,
      page_start: payload.page_start || 1,
    };
    try {
      const res = await network.request({
        cmdId: 600116,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data || {});
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取赛事所有二级玩法
  async getSelectedEventInfo(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      languageCode: getLanguageCode(get('language'))
    };
    try {
      const res: any = await network.request({
        cmdId: 3119662,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取体育语言数据
  async getSportsLocalization(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const lang = getLanguageCode(get('language'));
    if ((lang === 'eng' || !payload.IdList) && !payload.force) {
      return {};
    }

    const data = {
      ...payload,
      languageCodeList: lang.toUpperCase(),
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 3119671,
        // paramType: 1,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.resolve({});
      }
    } catch (error) {
      return Promise.resolve({});
    }
  },
  // 获取各平台 热门游戏数据和平台大类 下方getTotalGamesPlatform函数的变种 期权用的
  async getGamesPlatform() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const gameStore = useGameStore();
    const data = {
      tid: tenantInfo.value.tid,
      aseq: {
        aseq: config.aseqId
      },
      version_type: {
        version_type: 3 // 1 是棋牌, 2 是网赚 3, 是综合彩票
      },
      news: {
        page_start: 1,
        num_per_page: 1,
        chan_con: 8,
        state: 1,
        message_id: 10,
        sort_flag: 1,
        proxy: tenantInfo.value.proxy,
        apply: 8, // 1 棋牌；2 网赚；3 综合彩票；3 老版彩票； (apply后端设置默认为1，为可选参数)
        language: Number(window.localStorage.getItem('languageNum') || '0')
      },
      banners: {
        page_start: 1,
        num_per_page: 1,
        chan_con: 8,
        state: 1,
        message_id: 12,
        sort_flag: 1,
        proxy: tenantInfo.value.proxy,
        apply: 8,
        language: Number(window.localStorage.getItem('languageNum') || '0')
      },
      homeHotGames: {
        cust_id: userInfo.value.cust_id || '0'
      },
      proxyConfig: {
        proxy: tenantInfo.value.proxy
      },
      hotGames: {
        size: 12
      }
    };
    try {
      const res: any = await network.request({
        cmdId: 381119,
        paramType: 1,
        isApiV2: true,
        data: data
      });
      if (res && res.type === 'success' && res.data) {
        // 所有游戏销售状态
        gameStore.setGameAllStates(res.data.gameState);
        // 首页热门游戏
        let newDada: any = {};
        if (res.data.homeHotGames) {
          let arr = res.data.homeHotGames[1] || [];
          arr = arr.sort((prev, next) => {
            return next.sort_v - prev.sort_v;
          });
          this.newHotGames = arr;
          for (let key in res.data.homeHotGames) {
            if (Number(key) === 1) {
              newDada.hotGames = res.data.homeHotGames[key];
            } else if (Number(key) === 2) {
              newDada.hotElectronic = res.data.homeHotGames[key];
            } else if (Number(key) === 3) {
              newDada.hotFishing = res.data.homeHotGames[key];
            } else if (Number(key) === 4) {
              newDada.redEnvelope = res.data.homeHotGames[key];
            } else if (Number(key) === 5) {
              newDada.hotBoard = res.data.homeHotGames[key];
            }
          }
        }
        if (newDada.hotGames || newDada.hotElectronic || newDada.hotFishing || newDada.redEnvelope || newDada.hotBoard) {
          this.setSmallClassGame({ data: newDada });
        }
        // 热门棋牌
        this.hotGames = blackListFilter(res.data.hotGames, userInfo.value.blacklist_game, userInfo.value.cust_name);
        // 热门棋牌游戏状态
        this.qiPaiPlayGames = res.data.thirdQiPaiGames;
        // 所有第三方游戏大类数据
        const gameBigClass = { chess: [], electronic: [], sports: [], live: [], fishing: [] };
        res.data.thirdGames.forEach(item => {
          if (item.play_id) {
            if (config.getGameCategory(item.play_id) === 1) {
              // 棋牌
              gameBigClass.chess.push(item);
            } else if (config.getGameCategory(item.play_id) === 2) {
              // 电子
              gameBigClass.electronic.push(item);
            } else if (config.getGameCategory(item.play_id) === 5) {
              // 体育
              gameBigClass.sports.push(formatIMSport(item));
            } else if (config.getGameCategory(item.play_id) === 6) {
              // 视讯
              gameBigClass.live.push(item);
            } else if (config.getGameCategory(item.play_id) === 3) {
              gameBigClass.fishing.push(item);
            }
          }
        });
        this.gameBigClass = gameBigClass;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取游戏大类平台数据 reset为真重新请求
  async getTotalGamesPlatform(payload, reset = false) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const msgStore = useMsgStore();
    const haxiStore = useHaxiStore();
    const gameStore = useGameStore();
    const tenantStore = useTenantStore();
    reset && (this.GamesPlatform = null); // 为真重置数据
    const data = {
      tid: tenantInfo.value.tid,
      aseq: {
        aseq: config.aseqId
      },
      version_type: {
        version_type: payload.type ? payload.type : 3 // 1 是棋牌, 2 是网赚 3, 是综合彩票
      },
      news: {
        page_start: 1,
        num_per_page: payload?.news?.num_per_page || 999,
        chan_con: payload.chan_con || 8,
        state: 1,
        message_id: 10,
        sort_flag: 1,
        vip_level: userInfo.value.cust_level || 0,
        proxy: tenantInfo.value.proxy,
        apply: 8, // 1 棋牌；2 网赚；3 综合彩票；3 老版彩票； (apply后端设置默认为1，为可选参数)
        language: Number(window.localStorage.getItem('languageNum') || '0')
      },
      game_news: {
        page_start: 1,
        num_per_page: 9999,
        state: 1,
        message_id: 17,
        sort_flag: 1,
        tid: tenantInfo.value.tid,
        proxy: tenantInfo.value.proxy,
        chan_con: 0,
        apply: 8,
        ma_id: 10,
        vip_level: userInfo.value.cust_level || 0,
        type: getDeviceType(),
        language: 0 // 固定传中文语言类型，游戏公告在其他语言类型下不会返回
      },
      banners: {
        page_start: 1,
        num_per_page: payload?.banners?.num_per_page || 999,
        chan_con: payload.chan_con || 8,
        state: 1,
        message_id: 12,
        sort_flag: 1,
        proxy: tenantInfo.value.proxy,
        apply: 8,
        language: Number(window.localStorage.getItem('languageNum') || '0')
      },
      homeHotGames: {
        cust_id: userInfo.value.cust_id || '0'
      },
      proxyConfig: {
        proxy: tenantInfo.value.proxy
      },
      hotGames: {
        size: 12
      }
    };
    try {
      let res: any = {};
      if (!this.GamesPlatform) {
        res = await network.request({
          cmdId: 381119,
          paramType: 1,
          isApiV2: true,
          data: data
        });
      } else {
        res = this.GamesPlatform;
      }
      console.log('getTotalGamesPlatform:', res)
      this.GamesPlatform = res;
      if (res && res.type === 'success' && res.data) {
        // 菜单排序
        this.version_type = res.data.version_type
          .filter(item => item.sort_v !== 0)
          .sort((a, b) => {
            return b.sort_v - a.sort_v;
          });
        // todo 重复排序字段
        this.menuSort = this.version_type;
        // 是否开启cp
        this.isOpenCp = this.version_type.some(v => v.type === 2)
        // 所有游戏销售状态
        gameStore.setGameAllStates(res.data.gameState);
        // 公告
        msgStore.setNewLists(res.data.news.data);
        // 游戏公告
        msgStore.setGameNewsLists(res.data?.game_news?.data)
        // 轮播图
        msgStore.setBanners(res.data.banners.data);
        // 原创游戏
        if (res.data.originalGames) {
          this.setOriginalGames(res.data.originalGames);
        }
        // 区块链游戏
        if (res.data.hsGames) {
          haxiStore.setHaxiGames(res.data.hsGames);
        }

        // 首页热门游戏
        let newDada: any = {};
        if (res.data.homeHotGames) {
          let arr = res.data.homeHotGames[1] || [];
          if (res.data.homeHotGames[6]?.length) {
            arr = [...arr, ...res.data.homeHotGames[6]];
          }
          if (res.data.homeHotGames[7]?.length) {
            arr = [...arr, ...res.data.homeHotGames[7]];
          }
          arr = arr.sort((prev, next) => {
            const a = next.hot_val || next.hotVal;
            const b = prev.hot_val || prev.hotVal;
            return a - b;
          });
          this.newHotGames = arr;
          for (let key in res.data.homeHotGames) {
            if (Number(key) === 1) {
              newDada.hotGames = res.data.homeHotGames[key];
            } else if (Number(key) === 2) {
              newDada.hotElectronic = res.data.homeHotGames[key];
            } else if (Number(key) === 3) {
              newDada.hotFishing = res.data.homeHotGames[key];
            } else if (Number(key) === 4) {
              newDada.redEnvelope = res.data.homeHotGames[key];
            } else if (Number(key) === 5) {
              newDada.hotBoard = res.data.homeHotGames[key];
            } else if (Number(key) === 6) {
              // 原创游戏
              newDada.hotOriginGames = res.data.homeHotGames[key];
            } else if (Number(key) === 7) {
              // 哈希游戏
              newDada.hotHash = res.data.homeHotGames[key];
            }
          }
        }
        if (newDada.hotGames || newDada.hotElectronic || newDada.hotFishing || newDada.redEnvelope || newDada.hotBoard) {
          this.setSmallClassGame({ data: newDada });
        }
        // 代理盘配置
        tenantStore.setActiveConfiguration(res.data.proxyConfig);
        // 热门棋牌
        this.hotGames = blackListFilter(res.data.hotGames, userInfo.value.blacklist_game, userInfo.value.cust_name);
        // 热门棋牌游戏状态
        window.localStorage.setItem('QiPaiPlayGames', JSON.stringify(res.data.thirdQiPaiGames));
        this.qiPaiPlayGames = res.data.thirdQiPaiGames;
        // 所有第三方游戏大类数据
        const gameBigClass = { chess: [], electronic: [], sports: [], live: [], fishing: [] };
        res.data.thirdGames.forEach(item => {
          if (item.play_id) {
            if (config.getGameCategory(item.play_id) === 1) {
              // 棋牌
              gameBigClass.chess.push(item);
            } else if (config.getGameCategory(item.play_id) === 2) {
              // 电子
              gameBigClass.electronic.push(item);
            } else if (config.getGameCategory(item.play_id) === 5) {
              // 体育
              gameBigClass.sports.push(formatIMSport(item));
            } else if (config.getGameCategory(item.play_id) === 6) {
              // 视讯
              gameBigClass.live.push(item);
            } else if (config.getGameCategory(item.play_id) === 3) {
              gameBigClass.fishing.push(item);
            }
          }
        });
        this.gameBigClass = gameBigClass;
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 检测3方平台
  async checking3Game(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 321543,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 检测3方游戏余额限制
  async checking3GameLimited(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 315605,
        data: data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取第三方平台跳转url
  async get3GameUrl(payload) {
    const { hotData } = useHot();
    const sportsStore = useSportsStore();
    const { tenantInfo, userInfo } = hotData;
    const data = payload.data ? { ...payload.data } : {};
    data.tid = tenantInfo.value.tid;
    data.cust_id = userInfo.value.cust_id;
    data.play_id = payload.play_id || (payload.introduction ? payload.introduction.id || payload.introduction.default || payload.introduction.route : '');
    const isMobile = utils.isMobile();
    if (Number(data.play_id) === 1385) {
      data.isMobileOpen = isMobile ? 1 : 0
    }
    if (payload.type) {
      data.type = payload.type
    }
    data.isMobileOpenNew = isMobile ? 1 : 0
    try {
      const res = await network.request({
        cmdId: 314501,
        data: data,
        paramType: 0,
        isApiV2: true
      });
      if (res && res.type === 'success') {
        setTimeout(() => sportsStore.reqBlanceCollect(), 1000);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 购彩大厅数据请求
   */
  async purchaseHall(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      ...payload,
      cust_id: userInfo.value.cust_id || '',
      tid: tenantInfo.value.tid
    };
    try {
      let pretime = new Date().getTime(); // 请求前
      const res = await network.request({
        cmdId: 320542,
        data: data
      });
      let reqtime = new Date().getTime() - pretime; // 请求耗时
      if (res && res.type === 'success') {
        // 过滤用户的黑名单游戏
        const resData = utils.blackListFilter(res.data || [], userInfo.value.blacklist_game, userInfo.value.cust_name);
        for (let i = 0; i < resData.length; i++) {
          const obj = {
            stop_sale_offset: resData[i].stop_sale_offset,
            end_time: resData[i].end_time,
            db_sys_date: resData[i].sys_time + reqtime // 系统时间
          };
          resData[i].timeout = false;
          resData[i].stopTime = utils.stopTime(obj);
        }
        if (data.type === 16) {
          this.setGPIList(resData);
        } else if (data.type === 31) {
          this.setDBList(resData);
        } else if (data.type === 2) {
          this.setPC28List(resData);
        } else {
          this.hallData = resData;
        }
        if (payload.type === 0) {
          this.setHallDataAll(resData);
        };
        resData.old_type = payload.type; // 防止网络不好的情况下 数据错误
        return Promise.resolve(resData);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 通过play_name 获取游戏最近开奖记录和当前期数数据 play_name,play_name,... 多个逗号隔开
   */
  async reqCPInfoByPlayName(payload = {}) {
    const { hotData: { tenantInfo } } = useHot();
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    let pretime = new Date().getTime(); // 请求前
    try {
      const res = await network.request({
        cmdId: 320551,
        data: data
      });
      let reqtime = new Date().getTime() - pretime; // 请求耗时
      if (res && res.type === 'success') {
        const resData: any = res.data || [];
        for (let i = 0; i < resData.length; i++) {
          const obj = {
            stop_sale_offset: resData[i].stop_sale_offset,
            end_time: resData[i].new_end_time,
            db_sys_date: resData[i].db_sys_date + reqtime // 系统时间
          };
          resData[i].timeout = false;
          resData[i].stopTime = utils.stopTime(obj);
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /*
   * 获取所有彩票数据（不分销售状态）
   * */
  async getAllLottery() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid
    };
    try {
      const res = await network.request({
        cmdId: 320549,
        data: postdata
      });
      if (res && res.type === 'success') {
        const resData: any = res.data || [];
        const tmpObj = {};
        resData.forEach(i => {
          i.img_url && i.img_url.trim && (i.img_url = i.img_url.trim());
          const key = i.play_id + (i.play_cat === 4 && ElotType['SAMEID'].plays.indexOf(Number(i.play_id)) !== -1 ? '_' : '');
          tmpObj[key] = i;
        });
        this.allLotteryData = { ...this.allLotteryData, ...tmpObj };
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取天成彩票相关数据
   */
  async reqTianChengCp() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id
    };
    try {
      const res = await network.request({
        cmdId: 317104,
        data: postdata
      });
      if (res && res.type === 'success') {
        this.setTianChengList(res.data || []);
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 首页获取tab排序
   */
  async reqHomeTabSort(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    // 发起请求
    const postdata = {
      tid: tenantInfo.value.tid,
      version_type: payload.type ? payload.type : 1 // version_type =1 是棋牌，version_type=2是网赚 version_type=3是综合彩票
    };
    try {
      const res = await network.request({
        cmdId: 320338,
        data: postdata
      });
      if (res && res.type === 'success') {
        this.menuSort = ((res.data as any) || [])
          .filter(item => item.sort_v !== 0)
          .sort((a, b) => {
            return b.sort_v - a.sort_v;
          });
        return Promise.resolve(this.menuSort);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取沙巴彩票状态
   */
  async reqSBCPState() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res: any = await network.request({
        cmdId: 321547,
        data: [tenantInfo.value.tid, '1326'],
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.isShowSBCPBtn = +res.data.state === 3 || +res.data.state === 9;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取GPI彩票状态
   */
  async reqGPIstate() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res: any = await network.request({
        cmdId: 321547,
        data: [tenantInfo.value.tid, '504'],
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.isShowGPIBtn = +res.data.state === 3 || +res.data.state === 9;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求玩法定价方案(赔率)
   */
  async reqPlayPricingPlan(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 330951,
        data: [payload.play_id, payload.room_level, tenantInfo.value.tid],
        paramType: 2,
        timeout: payload.timeout || 1000 * 20
      });
      if (res && res.type === 'success') {
        let newPlayPricingPlan = Object.assign({}, this.playPricingPlan);
        newPlayPricingPlan[payload.play_id + '-' + payload.room_level] = res.data;
        this.playPricingPlan = newPlayPricingPlan;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 新 请求玩法定价方案(赔率)
   */
  async reqPlayPricingPlanNew(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 330955,
        data: [payload.play_id, payload.room_level, tenantInfo.value.tid],
        paramType: 2
      });
      if (res && res.type === 'success') {
        let newPlayPricingPlan = Object.assign({}, this.playPricingPlan);
        newPlayPricingPlan[payload.play_id + '-' + payload.room_level] = res.data;
        this.playPricingPlan = newPlayPricingPlan;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求玩法定价方案(赔率)
   */
  async reqPlayPricingPlanByCustId(payload) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    try {
      const cust_id = userInfo.value.cust_id || 0
      const res = await network.request({
        cmdId: 3309511,
        data: [payload.play_id, payload.room_level, tenantInfo.value.tid, cust_id],
        paramType: 2,
        timeout: payload.timeout || 1000 * 20
      });
      if (res && res.type === 'success') {
        let newPlayPricingPlan = Object.assign({}, this.playPricingPlan);
        newPlayPricingPlan[payload.play_id + '-' + payload.room_level] = res.data;
        this.playPricingPlan = newPlayPricingPlan;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 新 请求玩法定价方案(赔率)
   */
  async reqPlayPricingPlanNewByCustId(payload) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 3309551,
        data: [payload.play_id, payload.room_level, tenantInfo.value.tid, userInfo.value.cust_id],
        paramType: 2
      });
      if (res && res.type === 'success') {
        let newPlayPricingPlan = Object.assign({}, this.playPricingPlan);
        newPlayPricingPlan[payload.play_id + '-' + payload.room_level] = res.data;
        this.playPricingPlan = newPlayPricingPlan;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 检测玩法是否可玩
   */
  async testCanPlay(payload) {
    let play = config.getPlayInfo(payload.main_play_id);
    let getSec = (hours, min, sec) => {
      let retsec = Number(hours) * 60 * 60 * 1000;
      retsec += Number(min) * 60 * 1000;
      retsec += Number(sec) * 1000;
      return retsec;
    };
    let testTimeArr = timeArr => {
      if (timeArr == null) {
        return false;
      }
      let date = new Date();
      let curSec = getSec(date.getHours(), date.getMinutes(), date.getSeconds());
      let leftArr = timeArr[0].split(':');
      let rightArr = timeArr[1].split(':');
      let leftTime = getSec(leftArr[0], leftArr[1], leftArr[2]);
      let rightTime = getSec(rightArr[0], rightArr[1], rightArr[2]);
      let isInTime = true;
      if (leftTime > rightTime) {
        isInTime = curSec > leftTime || curSec < rightTime;
      } else {
        isInTime = curSec > leftTime && curSec < rightTime;
      }
      return isInTime;
    };
    let isInTime = true;
    let timeStr = '';
    if (play && play.time) {
      // 私彩销售时间优先采用后端数据 sell_start_time sell_end_time
      if (play.base && play.sell_start_time && play.sell_end_time) {
        if (play.sell_end_time === '00:00:00') {
          play.time = [play.sell_start_time, '24:00:00'];
        } else {
          play.time = [play.sell_start_time, play.sell_end_time];
        }
      }
      isInTime = testTimeArr(play.time);
      if (!isInTime) {
        timeStr = play.time[0] + ' ~ ' + play.time[1] + '';
        if (play.time2) {
          isInTime = testTimeArr(play.time2);
          if (!isInTime) {
            timeStr += '、' + play.time2[0] + ' ~ ' + play.time2[1] + '';
          }
        }
      }
    }

    if (!isInTime) {
      return Promise.reject({ level: 2, des: '已停盘，开盘时间为:' + timeStr });
    }
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let testState = st => {
      switch (Number(st)) {
        case 2:
          return Promise.reject({ level: 2, des: '暂停销售' }); // 暂停销售
        case 3:
          return Promise.reject({ level: 2, des: '停止销售' }); // 停止销售
        case 4:
          // 内部测试:
          if (userInfo.value.fict_flag !== 1) {
            return Promise.reject({
              level: 2,
              des: '当前彩种正处于内测阶段，敬请期待!'
            });
          }
      }
      // 可以进入
      return Promise.resolve({ response_code: 9999 });
    };
    const playId = payload.subplayid || payload.main_play_id;

    try {
      const res = await network.request({
        cmdId: 321535,
        data: [tenantInfo.value.tid, payload.main_play_id],
        paramType: 2
      });
      if (res && res.type === 'success') {
        if (JSON.stringify(res.data) !== JSON.stringify(this.gameTab)) {
          this.gameTab = res.data;
        }
        return testState(res.data[playId]);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 查询收藏
   */
  async checkIsFavorite(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 320568,
        data: {
          tid: tenantInfo.value.tid,
          cust_id: userInfo.value.cust_id,
          play_id: payload.play_id,
          play_cat: payload.play_cat
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求彩种当前期信息
   */
  async reqCurrentTerm(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    let play = config.getPlayInfo(payload.play_id);
    try {
      const res = await network.request({
        cmdId: 320334,
        data: [tenantInfo.value.tid, play.play_name],
        paramType: 2,
        timeout: payload.timeout || 1000 * 20
      });
      if (res && res.type === 'success') {
        let newTerm = Object.assign({}, res.data) as any;
        newTerm.startTime = startTime(res.data);
        newTerm.stopTime = stopTime(res.data);
        if (!isFrost(newTerm)) {
          let terms = {} as any;
          terms.key = payload.play_id;
          terms.data = newTerm;
          gameStore.setTerms(terms);
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求指定彩种的开奖公告
   */
  async reqTermHistory(payload) {
    let play = config.getPlayInfo(payload.play_id);
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    let data = {} as any;
    data.tid = tenantInfo.value.tid;
    data.play_name = play ? play.play_name : payload.play_name;
    data.count = payload.count ? payload.count : 10;
    try {
      const res = await network.request({
        cmdId: 320539,
        data,
        timeout: payload.timeout || 1000 * 20
      });
      if (res && res.type === 'success') {
        if (res.data && payload.count !== 1) {
          let resCopy = [];
          for (let i in res.data) {
            let historyItem = Object.assign({}, res.data[i]);
            if (historyItem.play_name.indexOf('K3') !== -1) {
              let code1 = parseInt(historyItem.win_code.slice(0, 1));
              let code2 = parseInt(historyItem.win_code.slice(1, 2));
              let code3 = parseInt(historyItem.win_code.slice(2, 3));
              let codeArr = [code1, code2, code3];
              codeArr.sort(function (a, b) {
                return a - b;
              });
              historyItem.win_code = codeArr.join('');
            }
            historyItem.local_win_code_arr = parseWincode(payload.play_id, historyItem);
            resCopy.push(historyItem);
          }
          let key = 'loop-' + payload.play_id;
          let dataForkey = {};
          dataForkey[key] = resCopy;
          // 为了解决香港彩开奖state一直刷新问题
          let oldData = gameStore.termHistorys;
          if (oldData && oldData[key] && oldData[key].length > 0) {
            if (oldData[key][0].term_code !== resCopy[0].term_code) {
              gameStore.setTermHistorys(dataForkey);
            }
          } else {
            gameStore.setTermHistorys(dataForkey);
          }
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求所有子玩法信息详情
   */
  async reqPlayDetailWithUpId(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res = await network.request({
        cmdId: 320142,
        data: [tenantInfo.value.tid, payload.up_play_id],
        paramType: 2,
        timeout: payload.timeout || 1000 * 20
      });
      if (res && res.type === 'success') {
        gameStore.setPlayDetails({ key: 'set', value: res.data });
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取游戏个人返点状态
   */
  async reqPersonalRebate(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res = await network.request({
        cmdId: 321544,
        data: {
          tid: tenantInfo.value.tid,
          play_id: payload.play_id
        }
      });
      if (res && res.type === 'success') {
        let obj = {};
        obj[payload.play_id] = res.data === 1;
        gameStore.setPersonalRebateStatus(obj);
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取自动投注玩法信息
   */
  async reqAutoBetPlayList() {
    const { hotData: { tenantInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 383017,
        data: {
          tid: tenantInfo.value.tid,
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 自动投注下注
   */
  async reqAutoBet(payload) {
    const { hotData: { userInfo, tenantInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 383013,
        data: {
          tid: tenantInfo.value.tid,
          cust_id: userInfo.value.cust_id,
          cust_name: userInfo.value.cust_name,
          chan_code: config.getChanCode(),
          plan_type: payload.plan_type, // 方案类型   1顺龙   2砍龙
          play_id: payload.play_id,
          play_name: payload.play_name,
          play_cname: payload.play_cname,
          splay_id: payload.splay_id,
          splay_name: payload.splay_name,
          term_cnt: payload.term_cnt, // 长龙期数
          per_issue: payload.per_issue, // 每隔 **期
          multiple: payload.multiple, // 倍数
          note_num: payload.note_num, // 投注次数
          per_amount: payload.per_amount, // 投注金额
          state: 1,
          platform: utils.getBetPlatform(), // 平台
          end_time: payload.end_time // 截止时间
        },
        paramType: 1,
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取自动投注记录
   */
  async reqAutoBetRecords(payload) {
    const { hotData: { userInfo, tenantInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 383014,
        data: {
          tid: tenantInfo.value.tid,
          cust_id: userInfo.value.cust_id,
          start_time: payload.start_time,
          end_time: payload.end_time,
          page_start: payload.page_start,
          num_per_page: payload.num_per_page
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取自动投注记录详情
   */
  async reqAutoBetRecordDetail(payload) {
    const { hotData: { tenantInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 383015,
        data: [
          tenantInfo.value.tid,
          payload.plan_id,
        ],
        paramType: 2,
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取自动投注记录详情
   */
  async reqCancelAutoBet(payload) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    try {
      const res = await network.request({
        cmdId: 383016,
        data: {
          tid: tenantInfo.value.tid,
          plan_id: payload.plan_id,
          state: 2,
          ori_state: 1,
          oper_code: userInfo.value.cust_code
        },
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
     * 获取长龙列表
     */
  async reqLongDragon() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res = await network.request({
        cmdId: 383004,
        data: {
          tid: tenantInfo.value.tid,
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  async reqBetFromBasket(payload, onlyGetRequestParameters = false) {
    const { hotData } = useHot();
    const { userInfo, tenantInfo } = hotData;
    const gameStore = useGameStore();
    const sportsStore = useSportsStore();

    let tid = tenantInfo.value.tenant.tid;
    let custId = userInfo.value.cust_id;
    let custCode = userInfo.value.cust_code;
    let key = payload.main_play_id;
    if (payload.key) {
      key = payload.key;
    }
    let roomLevel = 0;
    let playRoomId = 0;
    if (payload.room_level || payload.room_level === 0) {
      roomLevel = payload.room_level;
    }
    if (payload.play_room_id) {
      playRoomId = payload.play_room_id;
    }
    let termCode = null;
    let termPart = null;
    let isSale = this.termsStatus[payload.main_play_id];
    if (payload.term_code) {
      termCode = payload.term_code;
      if (payload.term_part) {
        termPart = payload.term_part;
        isSale = true;
      }
    } else {
      let term = this.terms[payload.main_play_id];
      if (term) {
        termCode = term.term_code;
        termPart = term.part_mon;
      }
    }
    let basket = this.baskets[key];
    if (basket == null) {
      return Promise.reject({ tag: 'Basket Bet 1', info: '请至少选择一注' });
    }
    if (basket.items && basket.items[payload.basketItemKey]) {
      let items = {};
      items[payload.basketItemKey] = basket.items[payload.basketItemKey];
      basket = { ...basket, keys: [payload.basketItemKey], items: items };
    }
    basket = Object.assign({}, basket);
    // 跟注修改金额时
    if (payload.basket) {
      basket = payload.basket;
    }
    let localerr = null;

    if (basket.keys == null || basket.keys.length === 0) {
      return Promise.reject({ tag: 'Basket Bet 2', info: '请至少选择一注' });
    }

    if (custId == null || custCode == null) localerr = { tag: 'Basket Bet 4', info: '用户信息出错!' };
    else if (termCode == null) localerr = { tag: 'Basket Bet 5', info: '无法获得期号信息' };
    else if (!isSale) localerr = { tag: 'Basket Bet 6', info: '当前期正在摇奖中，无法投注!' };
    else if (basket.length === 0) localerr = { tag: 'Basket Bet 7', info: '号码栏为空' };
    else if (basket.total_note < 1) localerr = { tag: 'Basket Bet 8', info: '请至少选择一注' };
    if (localerr) {
      return Promise.reject(localerr);
    }
    let isMobile = utils.isMobile();
    let mainPlay = config.getPlayInfo(payload.main_play_id);
    let isLHC = mainPlay.cat === 'MARKSIX' || mainPlay.cat === 'LHCJDC';
    let isYDC = mainPlay.cat === 'YDC';
    let changeMultix = isMobile && payload.ticketListAmountData && !isLHC && !isYDC;
    let plan = createPlan(payload.main_play_id, basket.multx, termCode, termPart, changeMultix);
    plan.lineTickets = []; // 解决折方案问题
    plan.room_level = roomLevel;
    plan.room_name = payload.room_name;
    plan.play_room_id = playRoomId;
    if (roomLevel && payload.play_name) {
      plan.room_name = payload.play_name + "_" + roomLevel
    }

    let isComAmount = false;
    for (let i = 0; i < basket.keys.length; i++) {
      let key = basket.keys[i];
      let basketItem = basket.items[key];
      let subPlay = config.getPlayInfo(basketItem.subplayid);
      // 特殊处理快3的和值玩法
      let noteMultx = changeMultix ? basket.multx : basketItem.multx;
      let basketMultx = changeMultix ? 1 : basket.multx;
      let tickets = [];
      plan.lineTickets.push(tickets);
      if (subPlay.cmd_signal_ball && !payload.isLR) {
        // 再剃掉利润率追号
        basketItem.ball_ids.map(item => {
          let newItem = Object.assign({}, basketItem);
          newItem.ball_ids = [item];
          newItem.note = 1;
          newItem.multx = noteMultx;
          let playTicket = {} as any;
          // 单独设置金额start
          let ballPrice = 1;
          let unitBallId = basketItem.subplayid + '-' + item;
          if (payload.ticketListAmountData) {
            payload.ticketListAmountData.useKey && (unitBallId = key + unitBallId);
            for (let tKey in payload.ticketListAmountData) {
              // eslint-disable-next-line no-prototype-builtins
              if (payload.ticketListAmountData.hasOwnProperty(tKey)) {
                if (tKey === unitBallId) {
                  ballPrice = Number(payload.ticketListAmountData[tKey].amount) * payload.quickUnit;
                }
              }
            }
          }
          // 单独设置金额end
          playTicket.ticket_id = 0;
          playTicket.ticket_code = 0;
          playTicket.note_num = 1;
          playTicket.multx = noteMultx;
          playTicket.play_name = subPlay.play_name;
          playTicket.play_id = subPlay.id;
          playTicket.play_cname = subPlay.play_cname;
          playTicket.play_cmd = getBasketItemCMD(payload.main_play_id, null, newItem) + '#B' + basketMultx;
          playTicket.tid = tid;
          playTicket.num = subPlay.simplex ? '' : JSON.stringify([newItem.ball_ids]);
          playTicket.rebate = newItem.rebate ? newItem.rebate : 0;
          if (payload.theme && payload.theme === 'themeDZ') {
            playTicket.note_price = basketItem.unit_price * 1000 * ballPrice * payload.plan_multx;
          } else {
            playTicket.note_price = basketItem.unit_price * 1000 * ballPrice;
          }
          plan.playPlanTickets.push(playTicket);
          tickets.push(playTicket);
        });
        // 六 合 彩对碰类玩法
      } else if (isPairBall(basketItem.subplayid)) {
        let pairballs = helperHK.getPairBalls(basketItem.subplayid, basketItem.ball_ids);
        // 单独设置金额start
        let unitBallId = basketItem.subplayid + '-' + JSON.stringify(pairballs);
        let ballPrice = 1;
        if (payload.ticketListAmountData) {
          payload.ticketListAmountData.useKey && (unitBallId = key + unitBallId);
          for (let tKey in payload.ticketListAmountData) {
            // eslint-disable-next-line no-prototype-builtins
            if (payload.ticketListAmountData.hasOwnProperty(tKey)) {
              if (tKey === unitBallId) {
                ballPrice = Number(payload.ticketListAmountData[tKey].amount) * payload.quickUnit;
              }
            }
          }
        }
        // 单独设置金额end
        pairballs.map(item => {
          let newItem = Object.assign({}, basketItem);
          newItem.note = 1;
          let playTicket = {} as any;
          playTicket.ticket_id = 0;
          playTicket.ticket_code = 0;
          playTicket.note_num = 1;
          playTicket.multx = noteMultx;
          playTicket.play_name = subPlay.play_name;
          playTicket.play_id = subPlay.id;
          playTicket.play_cname = subPlay.play_cname;
          playTicket.play_cmd = basketItem.subplayid + '#' + item[0] + item[1] + '*1*' + noteMultx + '#B' + basketMultx;
          playTicket.tid = tid;
          playTicket.num = JSON.stringify([item]);
          playTicket.rebate = newItem.rebate ? newItem.rebate : 0;
          playTicket.note_price = basketItem.unit_price * 1000 * ballPrice;
          plan.playPlanTickets.push(playTicket);
          tickets.push(playTicket);
        });
      } else {
        // 单独设置金额start
        let arrCodes = [];
        let arrCodeStr = '';
        let allAreaBalls = ballsWithArea(basketItem.subplayid, basketItem.ball_ids);
        for (let j in allAreaBalls) {
          let balls = allAreaBalls[j];
          let tmpStr = '';
          for (let k = 0; k < balls.length; k++) {
            let ball = balls[k];
            tmpStr += ball.id;
            arrCodeStr = ball.id
          }
          if (balls.length > 0) {
            arrCodes.push(tmpStr);
          }
        }
        // let unitBallId = basketItem.subplayid + '-' + arrCodes.join(',');
        let unitBallId = basketItem.subplayid + '-' + arrCodeStr;
        // 多个key拼在一起 获取不到,其实他们价格都一样
        let ballPrice = 1;
        if (payload.ticketListAmountData) {
          payload.ticketListAmountData.useKey && (unitBallId = key + unitBallId);
          for (let tKey in payload.ticketListAmountData) {
            // eslint-disable-next-line no-prototype-builtins
            if (payload.ticketListAmountData.hasOwnProperty(tKey)) {
              if (tKey === unitBallId) {
                ballPrice = Number(payload.ticketListAmountData[tKey].amount) * payload.quickUnit;
              }
            }
          }
        }
        console.log('ballPrice:', ballPrice)
        // 单独设置金额end
        let playTicket = {} as any;
        playTicket.ticket_id = 0;
        playTicket.ticket_code = 0;
        playTicket.note_num = basketItem.note;
        playTicket.multx = noteMultx;
        playTicket.play_name = subPlay.play_name;
        playTicket.play_id = subPlay.id;
        // playTicket.play_cname = subPlay.play_cname;
        playTicket.play_cname = basketItem.subPlayName;
        playTicket.play_cmd = getBasketItemCMD(payload.main_play_id, null, {
          ...basketItem,
          multx: noteMultx
        }) + '#B' + basketMultx;
        playTicket.tid = tid;
        playTicket.num = subPlay.simplex ? '' : JSON.stringify([basketItem.ball_ids]);
        playTicket.rebate = basketItem.rebate ? basketItem.rebate : 0;
        playTicket.note_price = basketItem.unit_price * 1000 * ballPrice;
        plan.playPlanTickets.push(playTicket);
        tickets.push(playTicket);
      }
      if (basketItem && !isComAmount) {
        for (let i in basketItem.ball_ids) {
          let ballInfo = config.getBallInfo(basketItem.subplayid);
          let ball = ballInfo.balls[Number(basketItem.ball_ids[i])];
          if (ball && ball.is_com_amount) {
            isComAmount = true;
            break;
          }
        }
      }
    }

    if (payload.totalAmount) {
      // 单独设置金额注单的总金额
      plan.amount = payload.totalAmount;
    } else {
      plan.amount = basket.total_amount;
    }
    plan.note_num = basket.total_note;
    plan.per_amount = plan.amount;
    plan.com_amount = isComAmount ? plan.amount : 0;
    if (payload.options) {
      plan = Object.assign(plan, payload.options);
    }
    plan.platform = utils.getBetPlatform();
    if (basket.IS_CHAT_FLOW_TICKET) {
      plan.playPlanTickets = plan.playPlanTickets.map(ele => {
        ele.note_price = basket.specical_note_price;
        return ele;
      });
    }

    if (payload.lineTicketsChange && plan.lineTickets[0].length > 0) {
      // 重新组装lineTickets（和pc保持一致）
      const newLineTickets = [];
      plan.lineTickets[0].forEach(obj => {
        newLineTickets.push([obj]);
      });
      plan.lineTickets = newLineTickets;
    }

    let key2 = payload.main_play_id;
    if (payload.key) {
      key2 = payload.key;
    }

    // 返回投注参数 用于分享投注和跟单展示
    if (onlyGetRequestParameters) {
      return sliptPlanWithSubPlay(plan)
    }

    try {
      console.log('sliptPlanWithSubPlay:', sliptPlanWithSubPlay(plan))
      const res: any = await network.request({
        cmdId: 320604,
        data: sliptPlanWithSubPlay(plan),
        paramType: 1
      });
      if (res && res.type === 'success') {
        if (payload.basketItemKey !== -1) {
          gameStore.removeItemFromBasket({
            main_play_id: payload.main_play_id,
            key: key2,
            rom_key: payload.basketItemKey
          });
        } else {
          gameStore.clearBasketWithPlayId({ main_play_id: payload.main_play_id, key: key2 });
        }
        res.data.plan = plan;
        res.data.basket = basket;
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 单独彩种校验时间
   */
  async checkingTime(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res: any = await network.request({
        cmdId: 320543,
        data: [tenantInfo.value.tid, payload.play_id, payload.term_code || 0],
        paramType: 2
      });
      if (res && res.type === 'success') {
        if (res.data) {
          const obj = {
            stop_sale_offset: res.data.stop_sale_offset,
            end_time: res.data.end_time,
            db_sys_date: res.data.sys_time
          };
          res.data.timeout = false;
          res.data.stopTime = stopTime(obj);
          gameStore.updateHallData(res.data);
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 获取子玩法下球号销售状态
   */
  async reqSubPlayBallsState(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res: any = await network.request({
        cmdId: 3201351,
        data: {
          tid: tenantInfo.value.tid,
          upper_id: payload.upper_id
        }
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
 * 获取DB彩票状态
 */
  async reqDBCPState() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res: any = await network.request({
        cmdId: 321547,
        data: [tenantInfo.value.tid, '1385'],
        paramType: 2
      });
      if (res && res.type === 'success') {
        this.isShowDBCPBtn = +res.data.state === 3 || +res.data.state === 9;
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  /**
   * 获取指定彩种的所有玩法状态
   */
  async reqSubPlayState(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    try {
      const res: any = await network.request({
        cmdId: 321535,
        data: [tenantInfo.value.tid, payload.upperId],
        paramType: 2
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求走势图
   */
  async reqCharts(payload) {
    let isApp = true;
    if (payload.isPC === true) {
      isApp = false;
    }
    let key = payload.key ? payload.key : payload.play_id;
    let trendCode = null;
    let play = config.getPlayInfo(payload.play_id);
    if (play) {
      let trendCfg = config.getChartConfig(play.cat, isApp);
      if (trendCfg && trendCfg.trends[parseInt(payload.trend_idx)]) {
        let trendId = trendCfg.trends[parseInt(payload.trend_idx)].trend;
        let chartid = payload.play_id;
        if (play.chartid) {
          chartid = play.chartid;
        }
        trendCode = chartid + '_' + trendId;
      }
    }
    if (trendCode) {
      try {
        const res: any = await network.request({
          cmdId: 321833,
          data: { trend_code: trendCode, num: payload.num }
        });
        if (res && res.type === 'success') {
          if (res.data && res.data.trendInstList) {
            for (let i in res.data.trendInstList) {
              res.data.trendInstList[i].trends_map = JSON.parse(res.data.trendInstList[i].trends_map);
              if (res.data.trend_name.indexOf('K3') !== -1) {
                let code1 = parseInt(res.data.trendInstList[i].win_code.slice(0, 1));
                let code2 = parseInt(res.data.trendInstList[i].win_code.slice(1, 2));
                let code3 = parseInt(res.data.trendInstList[i].win_code.slice(2, 3));
                let codeArr = [code1, code2, code3];
                codeArr.sort(function (a, b) {
                  return a - b;
                });
                res.data.trendInstList[i].win_code = codeArr.join('');
              }
            }
            const gameStore = useGameStore();
            gameStore.setCharts({ key: key, data: res.data });
          }
          return Promise.resolve(res.data);
        }
      } catch (error) {
        return Promise.reject(error.data);
      }
    } else {
      return Promise.reject({});
    }
  },
  /**
   * 请求房间类型列表
   */
  async reqChatByNames(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res: any = await network.request({
        cmdId: 321137,
        data: {
          tid: tenantInfo.value.tid,
          play_names: payload.play_names
        }
      });
      if (res && res.type === 'success') {
        gameStore.setRoomCat(res.data);
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求回水列表
   */
  async reqBackWaterList() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res: any = await network.request({
        cmdId: 321330,
        data: {
          tid: tenantInfo.value.tid,
          cust_id: userInfo.value.cust_id,
          cust_code: userInfo.value.cust_code,
          end_time: new Date().getTime(),
          start_time: new Date().getTime() - 30 * 24 * 3600 * 1000,
          page_start: 1,
          num_per_page: 10
        }
      });
      if (res && res.type === 'success') {
        if (res.data) {
          let list = [];
          if (res.data.data) {
            list = res.data.data;
            for (let index in list) {
              let item = list[index];
              switch (item.room_level) {
                case 1:
                  item.levelStr = '初级房';
                  break;
                case 2:
                  item.levelStr = '中级房';
                  break;
                case 3:
                  item.levelStr = '高级房';
                  break;
              }
              switch (Number(item.state)) {
                case 1:
                  item.stateStr = '待结算';
                  break;
                case 2:
                  item.stateStr = '已结算';
                  break;
                case 3:
                  item.stateStr = '待审核';
                  break;
                case 4:
                  item.stateStr = '待审批';
                  break;
                case 5:
                  item.stateStr = '已放款';
                  break;
                case 6:
                  item.stateStr = '已返回';
                  break;
              }
            }
          }
          gameStore.setBackWaterList(list);
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求回水比例
   */
  async reqBackWater(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    const data = [payload.play_id || 501, tenantInfo.value.tid];
    try {
      const res: any = await network.request({
        cmdId: 331133,
        data,
        paramType: 2
      });
      if (res && res.type === 'success') {
        if (res.data && data[0] === 501) {
          gameStore.setBackWater(res.data);
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求注单限制
   */
  async reqBetLimit() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    try {
      const res: any = await network.request({
        cmdId: 331102,
        data: {
          tid: tenantInfo.value.tid
        }
      });
      if (res && res.type === 'success') {
        if (res.data) {
          gameStore.setBetLimit(res.data);
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求房间类型列表
   */
  async reqRoomTypes(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const gameStore = useGameStore();
    let play = config.getPlayInfo(payload.main_play_id) || {};
    try {
      const res: any = await network.request({
        cmdId: 321135,
        data: [tenantInfo.value.tid, play.play_name],
        paramType: 2
      });
      if (res && res.type === 'success') {
        if (res.data) {
          gameStore.setRoomTypes({ key: payload.main_play_id, value: res.data });
        }
        return Promise.resolve(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 请求盈利榜
   */
  async reqProfitList(payload = {}) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      ...payload,
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 341533,
        data,
        paramType: 0,
        headerType: 1
      });
      if (res && res.type === 'success') {
        this.profitList = res.data || [];
        return Promise.resolve(res.data || []);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  /**
   * 根据tid获取中奖排行（分页）is_clear是否清空数据
   */
  async reqRewardRank(payload: any = { is_clear: true }) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    if (payload.is_clear) {
      this.playRewardRank = [];
    }
    let data = {
      ...payload,
      tid: tenantInfo.value.tid,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 20
    };
    try {
      const res: any = await network.request({
        cmdId: 320636,
        data,
        paramType: 0,
        headerType: 1
      });
      if (res && res.type === 'success') {
        this.playRewardRank = res.data || { count: 0, data: [] };
        return Promise.resolve(res.data || { count: 0, data: [] });
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取原创游戏订单和开奖
  async reqOriginalGameOrdersAndTerms() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 470100,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取Crash游戏开奖
  async reqCrashAllTerms(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470101,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Crash游戏下单
  async reqCrashBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const sportsStore = useSportsStore();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      showname: payload.showname || 'NO',
      amount: payload.amount,
      currency: payload.currency,
      seed_hash: payload.seed_hash,
      order_odds: payload.order_odds
    };
    try {
      const res: any = await network.request({
        cmdId: 470102,
        data
      });
      if (res && res.type === 'success') {
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取用户历史订单
  async reqOriginalGameOrders(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const relate10ys = Date.now() - 1000 * 3600 * 24 * 366 * 10;
    let data = {
      tid: tenantInfo.value.tid,
      cust_code: userInfo.value.cust_code,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10,
      start_time: payload.start_time || dayStartTimeNew(relate10ys),
      end_time: payload.end_time || dayEndTimeNew(Date.now()),
      gameName: payload.gameName
    };
    try {
      const res: any = await network.request({
        cmdId: 470103,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Crash游戏手动跳伞
  async reqCrashJump() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const sportsStore = useSportsStore();
    let data = {
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 470104,
        data
      });
      if (res && res.type === 'success') {
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 查询自定义数据
  async reqConfig() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id
    };
    try {
      const res: any = await network.request({
        cmdId: 470105,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 保存自定义数据
  async reqSaveConfig(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      custId: userInfo.value.cust_id,
      setting: payload.setting
    };
    try {
      const res: any = await network.request({
        cmdId: 470106,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取Crash游戏开奖
  async reqCrashTerms(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470107,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Crash游戏预下单
  async reqCrashPreBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const sportsStore = useSportsStore();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      showname: payload.showname || 'NO',
      amount: payload.amount,
      currency: payload.currency,
      seed_hash: payload.seed_hash,
      order_odds: payload.order_odds
    };
    try {
      const res: any = await network.request({
        cmdId: 470108,
        data
      });
      if (res && res.type === 'success') {
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Crash游戏取消预下单
  async cancelCrashPreBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const sportsStore = useSportsStore();
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      id: payload.id
    };
    try {
      const res: any = await network.request({
        cmdId: 470109,
        data
      });
      if (res && res.type === 'success') {
        sportsStore.reqBlanceCollect();
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // 获取原创游戏
  async reqOriginalGames(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      ...payload,
    } as any;
    if (userInfo.value?.cust_id) {
      data.cust_id = userInfo.value.cust_id;
    }
    try {
      const res: any = await network.request({
        cmdId: 470110,
        data
      });
      if (res && res.type === 'success') {
        this.setOriginalGames(res.data);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 查询当前用户进行中的游戏信息
  async reqMinesRunning(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      ...payload,
    };
    try {
      const res: any = await network.request({
        cmdId: 470111,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 下单
  async reqMinesBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      amount: payload.amount,
      client_seed: payload.client_seed, // 客户端种子 客户端生成
      mine_count: payload.mine_count // 地雷数量
    };
    try {
      const res: any = await network.request({
        cmdId: 470112,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 查询下次游戏使用的三个种子哈希值
  async reqMinesHashSeeds(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      ...payload,
    };
    try {
      const res: any = await network.request({
        cmdId: 470113,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 查询用户历史订单列表
  async reqMinesOrders(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const relate10ys = Date.now() - 1000 * 3600 * 24 * 366 * 10;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      start_time: payload.start_time || dayStartTimeNew(relate10ys),
      end_time: payload.end_time || dayEndTimeNew(Date.now()),
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470114,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 挖矿
  async reqMinesDig(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      order_id: payload.order_id,
      click_index: payload.click_index // 挖矿点击位置，字符类型 数值范围为0-24
    };
    try {
      const res: any = await network.request({
        cmdId: 470115,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Mines游戏 兑现
  async reqMinesCash(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      order_id: payload.order_id
    };
    try {
      const res: any = await network.request({
        cmdId: 470116,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Double游戏 下单
  async reqDoubleBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      amount: payload.amount,
      color: payload.color // 红 RED 黑 BLACK 白 WHITE
    };
    try {
      const res: any = await network.request({
        cmdId: 470119,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Double游戏 查询下次游戏使用的种子哈希值
  async reqDoubleHashSeeds() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid
    };
    try {
      const res: any = await network.request({
        cmdId: 470117,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Double游戏 查询用户历史订单列表
  async reqDoubleOrders(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const relate10ys = Date.now() - 1000 * 3600 * 24 * 366 * 10;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      start_time: payload.start_time || dayStartTimeNew(relate10ys),
      end_time: payload.end_time || dayEndTimeNew(Date.now()),
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470118,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Double游戏 查询已结束的历史开奖
  async reqDoubleTerms(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470120,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Dice游戏 下单
  async reqDiceBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      amount: payload.amount,
      client_seed: payload.client_seed, // 客户端种子
      order_command: payload.order_command, // 少于 LESSTHAN 大于 MORETHAN
      order_number: payload.order_number // 2-98 的整数值
    };
    try {
      const res: any = await network.request({
        cmdId: 470122,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Dice游戏 查询下次游戏使用的种子哈希值
  async reqDiceHashSeeds() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code
    };
    try {
      const res: any = await network.request({
        cmdId: 470121,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Dice游戏 查询用户历史订单列表
  async reqDiceOrders(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const relate10ys = Date.now() - 1000 * 3600 * 24 * 366 * 10;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      start_time: payload.start_time || dayStartTimeNew(relate10ys),
      end_time: payload.end_time || dayEndTimeNew(Date.now()),
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470123,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Ring游戏 查询下次游戏使用的种子哈希值
  async reqRingHashSeeds() {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code
    };
    try {
      const res: any = await network.request({
        cmdId: 470124,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Ring游戏 下单
  async reqRingBet(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      amount: payload.amount,
      client_seed: payload.client_seed, // 客户端种子
      hard_type: payload.hard_type, // 难度 字符类型 LOW、MEDIUM、HIGH、HARD
      level: payload.level // 段位 数字10、20、30、40、50
    };
    try {
      const res: any = await network.request({
        cmdId: 470125,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },
  // Ring游戏 查询用户历史订单列表
  async reqRingOrders(payload = {} as any) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;
    const relate10ys = Date.now() - 1000 * 3600 * 24 * 366 * 10;
    let data = {
      tid: tenantInfo.value.tid,
      cust_id: userInfo.value.cust_id,
      cust_code: userInfo.value.cust_code,
      start_time: payload.start_time || dayStartTimeNew(relate10ys),
      end_time: payload.end_time || dayEndTimeNew(Date.now()),
      page_start: payload.page_start || 1,
      num_per_page: payload.num_per_page || 10
    };
    try {
      const res: any = await network.request({
        cmdId: 470126,
        data
      });
      if (res && res.type === 'success') {
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res.data);
      }
    } catch (error) {
      return Promise.reject(error.data);
    }
  },

  // 查询幸运充值单
  async reqLuckRechargeNotes(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
    }

    const res: any = await network.request({
      cmdId: 397004,
      data
    });

    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 立即领取 幸运充值单
  async getLuckRechargeAward(payload) {
    const { hotData } = useHot();
    const { tenantInfo, userInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
      cust_code: userInfo.value.cust_code,
    }

    const res: any = await network.request({
      cmdId: 397005,
      data
    });

    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 获取跟投状态
  async getPlayState(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
    }

    const res: any = await network.request({
      cmdId: 400095,
      data,
    });

    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 获取游戏彩票&子玩法
  async reqLotteryNextData() {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;

    const data = {
      tid: tenantInfo.value.tid,
    }

    const res: any = await network.request({
      cmdId: 400100,
      data,
    });

    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 获取跟投计划期列表
  async getBettingTermList(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      ...payload.data,
      tid: tenantInfo.value.tid,
    }
    const res: any = await network.request({
      cmdId: 400063,
      data,
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },

  // 号源校验
  async checkNumberSource(payload) {
    const { hotData } = useHot();
    const { tenantInfo } = hotData;
    const data = {
      ...payload,
      tid: tenantInfo.value.tid,
    }
    const res: any = await network.request({
      cmdId: 320550,
      data,
      paramType: 0,
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
  // 获取赔率说明 play_id resource_id:房间等级
  async reqPeiLvData(payload) {
    const { hotData: { tenantInfo, userInfo } } = useHot();
    const data = {
      ...payload,
      cust_id: userInfo.value.cust_id,
      tid: tenantInfo.value.tid,
      play_id: payload.play_id,
      resource_id: payload.resource_id,
    }
    const res: any = await network.request({
      cmdId: 331138,
      data,
      paramType: 0,
    });
    if (res && res.type === 'success') {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
};
