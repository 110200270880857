// 香港彩
export default {
  plays: {
    // 特码
    0: {
      rule_id: 33000,
      area_id: 33000,
      play_name: 'TM',
      play_cname: '特码',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      is_combine_cmd: true
    },
    // 色波
    1: {
      rule_id: 33001,
      area_id: 33001,
      play_name: 'SB',
      play_cname: '色波',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    // 特肖
    2: {
      rule_id: 33002,
      area_id: 33002,
      play_name: 'TX',
      play_cname: '特肖',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },
    // 正码
    3: {
      rule_id: 33003,
      area_id: 33003,
      play_name: 'ZM',
      play_cname: '正码',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    // 正特
    4: {
      rule_id: 33004,
      area_id: 33004,
      play_name: 'ZMT1',
      play_cname: '正码特一',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    5: {
      rule_id: 33005,
      area_id: 33005,
      play_name: 'ZMT2',
      play_cname: '正码特二',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    6: {
      rule_id: 33006,
      area_id: 33006,
      play_name: 'ZMT3',
      play_cname: '正码特三',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    7: {
      rule_id: 33007,
      area_id: 33007,
      play_name: 'ZMT4',
      play_cname: '正码特四',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    8: {
      rule_id: 33008,
      area_id: 33008,
      play_name: 'ZMT5',
      play_cname: '正码特五',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    9: {
      rule_id: 33009,
      area_id: 33009,
      play_name: 'ZMT6',
      play_cname: '正码特六',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true
    },
    // 正码1-6
    10: {
      rule_id: 33010,
      area_id: 33010,
      play_name: 'ZM1D6',
      play_cname: '正码1-6',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      is_combine_cmd: true
    },
    // 连码
    11: {
      rule_id: 33011,
      area_id: 33011,
      play_name: 'LM3Z2DXFS',
      play_cname: '三中二单选/复式',
      unit_price: 1,
      note_tag: 'sscComb3',
      cmd_signal_ball: false
    },
    12: {
      rule_id: 33012,
      area_id: 33012,
      play_name: 'LM3Z2DT',
      play_cname: '三中二胆拖',
      unit_price: 1,
      note_tag: '3xiaodt',
      cmd_signal_ball: false
    },
    13: {
      rule_id: 33013,
      area_id: 33013,
      play_name: 'LM3QZDXFS',
      play_cname: '三全中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb3',
      cmd_signal_ball: false
    },
    14: {
      rule_id: 33014,
      area_id: 33014,
      play_name: 'LM3QZDT',
      play_cname: '三全中胆拖',
      unit_price: 1,
      note_tag: '3xiaodt',
      cmd_signal_ball: false
    },
    15: {
      rule_id: 33015,
      area_id: 33015,
      play_name: 'LM2QZDXFS',
      play_cname: '二全中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false
    },
    16: {
      rule_id: 33016,
      area_id: 33016,
      play_name: 'LM2QZDT',
      play_cname: '二全中胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false
    },
    17: {
      rule_id: 33017,
      area_id: 33017,
      play_name: 'LM2QZSXDP',
      play_cname: '二全中生肖对碰',
      unit_price: 1,
      note_tag: '2xiaopen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    18: {
      rule_id: 33018,
      area_id: 33018,
      play_name: 'LM2QZWSDP',
      play_cname: '二全中尾数对碰',
      unit_price: 1,
      note_tag: '2weipen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    19: {
      rule_id: 33019,
      area_id: 33019,
      play_name: 'LM2QZHHDP',
      play_cname: '二全中混合对碰',
      unit_price: 1,
      note_tag: '2hunpen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    20: {
      rule_id: 33020,
      area_id: 33020,
      play_name: 'LM2ZTDXFX',
      play_cname: '二中特单选/复选',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false
    },
    21: {
      rule_id: 33021,
      area_id: 33021,
      play_name: 'LM2ZTDT',
      play_cname: '二中特胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false
    },
    22: {
      rule_id: 33022,
      area_id: 33022,
      play_name: 'LM2ZTSXDP',
      play_cname: '二中特生肖对碰',
      unit_price: 1,
      note_tag: '2xiaopen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    23: {
      rule_id: 33023,
      area_id: 33023,
      play_name: 'LM2ZTWSDP',
      play_cname: '二中特尾数对碰',
      unit_price: 1,
      note_tag: '2weipen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    24: {
      rule_id: 33024,
      area_id: 33024,
      play_name: 'LM2ZTHHDP',
      play_cname: '二中特混合对碰',
      unit_price: 1,
      note_tag: '2hunpen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    25: {
      rule_id: 33025,
      area_id: 33025,
      play_name: 'LMTCDXFX',
      play_cname: '特串单选/复选',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false
    },
    26: {
      rule_id: 33026,
      area_id: 33026,
      play_name: 'LMTCDT',
      play_cname: '特串胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false
    },
    27: {
      rule_id: 33027,
      area_id: 33027,
      play_name: 'LMTCSXDP',
      play_cname: '特串生肖对碰',
      unit_price: 1,
      note_tag: '2xiaopen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    28: {
      rule_id: 33028,
      area_id: 33028,
      play_name: 'LMTCWSDP',
      play_cname: '特串尾数对碰',
      unit_price: 1,
      note_tag: '2weipen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },
    29: {
      rule_id: 33029,
      area_id: 33029,
      play_name: 'LMTCHD',
      play_cname: '特串混合对碰',
      unit_price: 1,
      note_tag: '2hunpen',
      cmd_signal_ball: false,
      cmd_pair_ball: true
    },

    30: {
      rule_id: 33030,
      area_id: 33030,
      play_name: 'YX',
      play_cname: '一肖',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true,
      is_combine_cmd: true
    },
    31: {
      rule_id: 33031,
      area_id: 33031,
      play_name: 'WS',
      play_cname: '尾数',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: true
    },

    // 合肖
    32: {
      rule_id: 33032,
      area_id: 33032,
      play_name: 'HX1XZ',
      play_cname: '一肖中',
      unit_price: 1,
      note_tag: 'nocount1',
      cmd_signal_ball: false
    },
    33: {
      rule_id: 33033,
      area_id: 33033,
      play_name: 'HX1XBZ',
      play_cname: '一肖不中',
      unit_price: 1,
      note_tag: 'nocount1',
      cmd_signal_ball: false
    },
    34: {
      rule_id: 33034,
      area_id: 33034,
      play_name: 'HX2XZ',
      play_cname: '二肖中',
      unit_price: 1,
      note_tag: 'nocount2',
      cmd_signal_ball: false
    },
    35: {
      rule_id: 33035,
      area_id: 33035,
      play_name: 'HX2XBZ',
      play_cname: '二肖不中',
      unit_price: 1,
      note_tag: 'nocount2',
      cmd_signal_ball: false
    },
    36: {
      rule_id: 33036,
      area_id: 33036,
      play_name: 'HX3XZ',
      play_cname: '三肖中',
      unit_price: 1,
      note_tag: 'nocount3',
      cmd_signal_ball: false
    },
    37: {
      rule_id: 33037,
      area_id: 33037,
      play_name: 'HX3XBZ',
      play_cname: '三肖不中',
      unit_price: 1,
      note_tag: 'nocount3',
      cmd_signal_ball: false
    },
    38: {
      rule_id: 33038,
      area_id: 33038,
      play_name: 'HX4XZ',
      play_cname: '四肖中',
      unit_price: 1,
      note_tag: 'nocount4',
      cmd_signal_ball: false
    },
    39: {
      rule_id: 33039,
      area_id: 33039,
      play_name: 'HX4XBZ',
      play_cname: '四肖不中',
      unit_price: 1,
      note_tag: 'nocount4',
      cmd_signal_ball: false
    },
    40: {
      rule_id: 33040,
      area_id: 33040,
      play_name: 'HX5XZ',
      play_cname: '五肖中',
      unit_price: 1,
      note_tag: 'nocount5',
      cmd_signal_ball: false
    },
    41: {
      rule_id: 33041,
      area_id: 33041,
      play_name: 'HX5XBZ',
      play_cname: '五肖不中',
      unit_price: 1,
      note_tag: 'nocount5',
      cmd_signal_ball: false
    },
    42: {
      rule_id: 33042,
      area_id: 33042,
      play_name: 'HX6XZ',
      play_cname: '六肖中',
      unit_price: 1,
      note_tag: 'nocount6',
      cmd_signal_ball: false
    },
    43: {
      rule_id: 33043,
      area_id: 33043,
      play_name: 'HX6XBZ',
      play_cname: '六肖不中',
      unit_price: 1,
      note_tag: 'nocount6',
      cmd_signal_ball: false
    },
    44: {
      rule_id: 33044,
      area_id: 33044,
      play_name: 'HX7XZ',
      play_cname: '七肖中',
      unit_price: 1,
      note_tag: 'nocount7',
      cmd_signal_ball: false
    },
    45: {
      rule_id: 33045,
      area_id: 33045,
      play_name: 'HX7XBZ',
      play_cname: '七肖不中',
      unit_price: 1,
      note_tag: 'nocount7',
      cmd_signal_ball: false
    },
    46: {
      rule_id: 33046,
      area_id: 33046,
      play_name: 'HX8XZ',
      play_cname: '八肖中',
      unit_price: 1,
      note_tag: 'nocount8',
      cmd_signal_ball: false
    },
    47: {
      rule_id: 33047,
      area_id: 33047,
      play_name: 'HX8XBZ',
      play_cname: '八肖不中',
      unit_price: 1,
      note_tag: 'nocount8',
      cmd_signal_ball: false
    },
    48: {
      rule_id: 33048,
      area_id: 33048,
      play_name: 'HX9XZ',
      play_cname: '九肖中',
      unit_price: 1,
      note_tag: 'nocount9',
      cmd_signal_ball: false
    },
    49: {
      rule_id: 33049,
      area_id: 33049,
      play_name: 'HX9XBZ',
      play_cname: '九肖不中',
      unit_price: 1,
      note_tag: 'nocount9',
      cmd_signal_ball: false
    },
    50: {
      rule_id: 33050,
      area_id: 33050,
      play_name: 'HX10XZ',
      play_cname: '十肖中',
      unit_price: 1,
      note_tag: 'nocount10',
      cmd_signal_ball: false
    },
    51: {
      rule_id: 33051,
      area_id: 33051,
      play_name: 'HX10XBZ',
      play_cname: '十肖不中',
      unit_price: 1,
      note_tag: 'nocount10',
      cmd_signal_ball: false
    },
    52: {
      rule_id: 33052,
      area_id: 33052,
      play_name: 'HX11XZ',
      play_cname: '十一肖中',
      unit_price: 1,
      note_tag: 'nocount11',
      cmd_signal_ball: false
    },
    53: {
      rule_id: 33053,
      area_id: 33053,
      play_name: 'HX11XBZ',
      play_cname: '十一肖不中',
      unit_price: 1,
      note_tag: 'nocount11',
      cmd_signal_ball: false
    },

    // 自选不中
    54: {
      rule_id: 33054,
      area_id: 33054,
      play_name: 'ZXBZ5BZ',
      play_cname: '五不中',
      unit_price: 1,
      note_tag: 'nocount5',
      cmd_signal_ball: false
    },
    55: {
      rule_id: 33055,
      area_id: 33055,
      play_name: 'ZXBZ6BZ',
      play_cname: '六不中',
      unit_price: 1,
      note_tag: 'nocount6',
      cmd_signal_ball: false
    },
    56: {
      rule_id: 33056,
      area_id: 33056,
      play_name: 'ZXBZ7BZ',
      play_cname: '七不中',
      unit_price: 1,
      note_tag: 'nocount7',
      cmd_signal_ball: false
    },
    57: {
      rule_id: 33057,
      area_id: 33057,
      play_name: 'ZXBZ8BZ',
      play_cname: '八不中',
      unit_price: 1,
      note_tag: 'nocount8',
      cmd_signal_ball: false
    },
    58: {
      rule_id: 33058,
      area_id: 33058,
      play_name: 'ZXBZ9BZ',
      play_cname: '九不中',
      unit_price: 1,
      note_tag: 'nocount9',
      cmd_signal_ball: false
    },
    59: {
      rule_id: 33059,
      area_id: 33059,
      play_name: 'ZXBZ10BZ',
      play_cname: '十不中',
      unit_price: 1,
      note_tag: 'nocount10',
      cmd_signal_ball: false
    },
    60: {
      rule_id: 33060,
      area_id: 33060,
      play_name: 'ZXBZ11BZ',
      play_cname: '十一不中',
      unit_price: 1,
      note_tag: 'nocount11',
      cmd_signal_ball: false
    },
    61: {
      rule_id: 33061,
      area_id: 33061,
      play_name: 'ZXBZ12BZ',
      play_cname: '十二不中',
      unit_price: 1,
      note_tag: 'nocount12',
      cmd_signal_ball: false
    },

    // 连肖
    62: {
      rule_id: 33062,
      area_id: 33062,
      play_name: 'LX2XDXFS',
      play_cname: '二肖单选/复式',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false,
      ball_odds: true
    },
    63: {
      rule_id: 33063,
      area_id: 33063,
      play_name: 'LX2XDT',
      play_cname: '二肖胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    64: {
      rule_id: 33064,
      area_id: 33064,
      play_name: 'LX3XDXFS',
      play_cname: '三肖单选/复式',
      unit_price: 1,
      note_tag: 'sscComb3',
      cmd_signal_ball: false,
      ball_odds: true
    },
    65: {
      rule_id: 33065,
      area_id: 33065,
      play_name: 'LX3XDT',
      play_cname: '三肖胆拖',
      unit_price: 1,
      note_tag: '3xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    66: {
      rule_id: 33066,
      area_id: 33066,
      play_name: 'LX4XDXFS',
      play_cname: '四肖单选/复式',
      unit_price: 1,
      note_tag: 'sscComb4',
      cmd_signal_ball: false,
      ball_odds: true
    },
    67: {
      rule_id: 33067,
      area_id: 33067,
      play_name: 'LX4XDT',
      play_cname: '四肖胆拖',
      unit_price: 1,
      note_tag: '4xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    68: {
      rule_id: 33068,
      area_id: 33068,
      play_name: 'LX5XDXFS',
      play_cname: '五肖单选/复式',
      unit_price: 1,
      note_tag: 'sscComb5',
      cmd_signal_ball: false,
      ball_odds: true
    },
    69: {
      rule_id: 33069,
      area_id: 33069,
      play_name: 'LX5XDT',
      play_cname: '五肖胆拖',
      unit_price: 1,
      note_tag: '5xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },

    // 连尾
    70: {
      rule_id: 33070,
      area_id: 33070,
      play_name: 'LW2WDXFS',
      play_cname: '二尾单选/复式',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false,
      ball_odds: true
    },
    71: {
      rule_id: 33071,
      area_id: 33071,
      play_name: 'LW2WDT',
      play_cname: '二尾胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    72: {
      rule_id: 33072,
      area_id: 33072,
      play_name: 'LW3WDXFS',
      play_cname: '三尾单选/复式',
      unit_price: 1,
      note_tag: 'sscComb3',
      cmd_signal_ball: false,
      ball_odds: true
    },
    73: {
      rule_id: 33073,
      area_id: 33073,
      play_name: 'LW3WDT',
      play_cname: '三尾胆拖',
      unit_price: 1,
      note_tag: '3xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    74: {
      rule_id: 33074,
      area_id: 33074,
      play_name: 'LW4WDXFS',
      play_cname: '四尾单选/复式',
      unit_price: 1,
      note_tag: 'sscComb4',
      cmd_signal_ball: false,
      ball_odds: true
    },
    75: {
      rule_id: 33075,
      area_id: 33075,
      play_name: 'LW4WDT',
      play_cname: '四尾胆拖',
      unit_price: 1,
      note_tag: '4xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    76: {
      rule_id: 33076,
      area_id: 33076,
      play_name: 'LW2WBZDXFS',
      play_cname: '二尾不中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb2',
      cmd_signal_ball: false,
      ball_odds: true
    },
    77: {
      rule_id: 33077,
      area_id: 33077,
      play_name: 'LW2WBZDT',
      play_cname: '二尾不中胆拖',
      unit_price: 1,
      note_tag: '2xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    78: {
      rule_id: 33078,
      area_id: 33078,
      play_name: 'LW3WBZDXFS',
      play_cname: '三尾不中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb3',
      cmd_signal_ball: false,
      ball_odds: true
    },
    79: {
      rule_id: 33079,
      area_id: 33079,
      play_name: 'LW3WBZDT',
      play_cname: '三尾不中胆拖',
      unit_price: 1,
      note_tag: '3xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    80: {
      rule_id: 33080,
      area_id: 33080,
      play_name: 'LW4WBZDXFS',
      play_cname: '四尾不中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb4',
      cmd_signal_ball: false,
      ball_odds: true
    },
    81: {
      rule_id: 33081,
      area_id: 33081,
      play_name: 'LW4WBZDT',
      play_cname: '四尾不中胆拖',
      unit_price: 1,
      note_tag: '4xiaodt',
      cmd_signal_ball: false,
      ball_odds: true
    },
    // 龙虎
    82: {
      rule_id: 33082,
      area_id: 33082,
      play_name: 'LHD',
      play_cname: '龙虎斗',
      unit_price: 1,
      note_tag: 'count',
      cmd_signal_ball: true,
      ball_odds: false
    },
    // 83: {
    //   rule_id: 33000,
    //   area_id: 33000,
    //   play_name: 'TMQT',
    //   play_cname: '两面',
    //   unit_price: 1,
    //   note_tag: 'tema',
    //   cmd_signal_ball: true,
    //   ball_odds: false
    // },
    // 连码四全中
    83: {
      rule_id: 33083,
      area_id: 33083,
      play_name: 'LM4QZDXFS',
      play_cname: '四全中单选/复式',
      unit_price: 1,
      note_tag: 'sscComb4',
      cmd_signal_ball: false
    },
    84: {
      rule_id: 33084,
      area_id: 33084,
      play_name: 'LM4QZTD',
      play_cname: '四全中胆拖',
      unit_price: 1,
      note_tag: '4xiaodt',
      cmd_signal_ball: false
    },
  }
}
